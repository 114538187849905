// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statusBar_statusBar__j-4ve {
  position: absolute;
  bottom: 0px;
  right: 0;
  width: calc(100% - 20px);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--darkest);
  font-size: 13px;
  color: var(--white);
  padding-left: 20px;
  opacity: 0.8;
}
.statusBar_success__HyFUo {
}
.statusBar_center__PlGg- {
  margin-left: -150px;
}
.statusBar_status__PEJ4Q {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: var(--green);
  margin-left: 10px;
}
.statusBar_right__GUzTd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background: rgb(20, 20, 20); */
  height: 100%;
  padding: 0px 20px 0px 20px;
}
.statusBar_green__mVy1t {
  color: var(--green);
}
.statusBar_show_success__xUR\\+H {
  animation: statusBar_fade_green__00KpI 3s;
}
@keyframes statusBar_fade_green__00KpI {
  0% {
    background: var(--darkest);
    color: var(--white);
  }
  10% {
    background: rgb(53, 110, 67);
    color: white;
  }
  90% {
    background: rgb(53, 110, 67);
    color: white;
  }
  100% {
    background: var(--darkest);
    color: var(--white);
  }
}
`, "",{"version":3,"sources":["webpack://./src/molecules/statusBar/statusBar.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,wBAAwB;EACxB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,0BAA0B;EAC1B,eAAe;EACf,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;AACd;AACA;AACA;AACA;EACE,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,WAAW;EACX,mBAAmB;EACnB,wBAAwB;EACxB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,iCAAiC;EACjC,YAAY;EACZ,0BAA0B;AAC5B;AACA;EACE,mBAAmB;AACrB;AACA;EACE,yCAAwB;AAC1B;AACA;EACE;IACE,0BAA0B;IAC1B,mBAAmB;EACrB;EACA;IACE,4BAA4B;IAC5B,YAAY;EACd;EACA;IACE,4BAA4B;IAC5B,YAAY;EACd;EACA;IACE,0BAA0B;IAC1B,mBAAmB;EACrB;AACF","sourcesContent":[".statusBar {\n  position: absolute;\n  bottom: 0px;\n  right: 0;\n  width: calc(100% - 20px);\n  height: 32px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background: var(--darkest);\n  font-size: 13px;\n  color: var(--white);\n  padding-left: 20px;\n  opacity: 0.8;\n}\n.success {\n}\n.center {\n  margin-left: -150px;\n}\n.status {\n  width: 8px;\n  height: 8px;\n  border-radius: 100%;\n  background: var(--green);\n  margin-left: 10px;\n}\n.right {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  /* background: rgb(20, 20, 20); */\n  height: 100%;\n  padding: 0px 20px 0px 20px;\n}\n.green {\n  color: var(--green);\n}\n.show_success {\n  animation: fade_green 3s;\n}\n@keyframes fade_green {\n  0% {\n    background: var(--darkest);\n    color: var(--white);\n  }\n  10% {\n    background: rgb(53, 110, 67);\n    color: white;\n  }\n  90% {\n    background: rgb(53, 110, 67);\n    color: white;\n  }\n  100% {\n    background: var(--darkest);\n    color: var(--white);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusBar": `statusBar_statusBar__j-4ve`,
	"success": `statusBar_success__HyFUo`,
	"center": `statusBar_center__PlGg-`,
	"status": `statusBar_status__PEJ4Q`,
	"right": `statusBar_right__GUzTd`,
	"green": `statusBar_green__mVy1t`,
	"show_success": `statusBar_show_success__xUR+H`,
	"fade_green": `statusBar_fade_green__00KpI`
};
export default ___CSS_LOADER_EXPORT___;
