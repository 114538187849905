import React, { useEffect, useRef, useState } from "react";
import styles from "./table.module.css";
import {
  fc_atoms
} from '../../uiSrc';

export const SortableColumnsTable = (props) => {
  const {
    Button,
    PaginationNumberInput,
    NumberInput,
  } = fc_atoms;
  const ref = useRef(null);
  const [containerHeight, setContainerHeight] = useState(100);
  const [headers, setHeaders] = useState([]);
  const { data } = props;
  useEffect(() => {
    if (data && data.length && Array.isArray(data[0])) {
      const headers = [];
      data[0].map((arr, i) => {
        if (i === 0) {
          Object.keys(arr).map((key, i) => {
            headers.push(key);
          });
        }
      });
      setHeaders(headers);
      if (!props.defaultFullHeight) {
        setContainerHeight(ref.current.parentNode.parentNode.clientHeight);
      }
    }
  }, [data]);

  return (
    <div
      className={styles.outer_container}
      style={{ height: props.outerHeight }}
      ref={ref}
    >
      <div
        className={styles.table_container}
        style={
          props.defaultFullHeight
            ? { height: "calc(100vh - 270px)" }
            : { height: containerHeight - 145 }
        }
      >
        {data.length && Array.isArray(data[0]) && data[0].length ? (
          <table className={styles.table}>
            <thead>
              <tr>
                <th
                  key={props.keyColumn}
                  onClick={() => {
                    props.onSort(props.keyColumn);
                  }}
                  className={
                    props.keyColumn === props.sortKey
                      ? styles.active_header
                      : ""
                  }
                  style={
                    props.centeredHeaders.indexOf(
                      props.keyColumn.toUpperCase()
                    ) !== -1
                      ? { textAlign: "center" }
                      : {}
                  }
                >
                  {props.keyColumn === props.sortKey ? (
                    <span>
                      {props.sortDir === "asc"
                        ? "↑"
                        : props.sortDir === "desc"
                        ? "↓"
                        : ""}
                      &nbsp;{props.keyColumn.replace(/_/g, " ")}
                    </span>
                  ) : (
                    props.keyColumn.replace(/_/g, " ")
                  )}
                </th>
                {headers
                  .filter((j) => props.keyColumn !== j)
                  .map((key) => (
                    <th
                      key={key}
                      onClick={() => {
                        props.onSort(key);
                      }}
                      className={
                        key === props.sortKey ? styles.active_header : ""
                      }
                      style={
                        props.centeredHeaders.indexOf(key.toUpperCase()) !== -1
                          ? { textAlign: "center" }
                          : {}
                      }
                    >
                      {key === props.sortKey ? (
                        <span>
                          {props.sortDir === "asc"
                            ? "↑"
                            : props.sortDir === "desc"
                            ? "↓"
                            : ""}
                          &nbsp;{key.replace(/_/g, " ")}
                        </span>
                      ) : (
                        key.replace(/_/g, " ")
                      )}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {data.length &&
                Array.isArray(data[0]) &&
                data.map((rowGroup, i) => {
                  if (rowGroup.length && i === props.pageNum) {
                    return rowGroup.map((thisRow) => {
                      return (
                        <tr key={Math.random()}>
                          <td key={Math.random()}>
                            {thisRow[props.keyColumn]}
                          </td>
                          {Object.keys(thisRow)
                            .filter((k) => k !== props.keyColumn)
                            .map((k) => (
                              <td
                                key={Math.random()}
                                style={
                                  props.centeredHeaders.indexOf(
                                    k.toUpperCase()
                                  ) !== -1
                                    ? { textAlign: "center" }
                                    : {}
                                }
                              >
                                {thisRow[k]}
                              </td>
                            ))}
                        </tr>
                      );
                    });
                  } else {
                    return <tr key={Math.random()}></tr>;
                  }
                })}
            </tbody>
          </table>
        ) : (
          <div
            className="inline_center"
            style={{ height: 200, width: "100%", opacity: 0.5 }}
          >
            No data
          </div>
        )}
      </div>
      <div className={styles.footer}>
        {data && data[0] && !props.hideButtons && Array.isArray(data[0]) && (
          <div className={styles.footer_buttons}>
            <Button
              style={{ marginRight: 30 }}
              onClick={() => props.setPageNum(props.pageNum - 1)}
            >
              Previous
            </Button>
            <NumberInput
              style={{ marginRight: 10, width: 35 }}
              value={props.pageNum}
              onChange={(val) => {
                props.setPageNum(parseInt(val));
              }}
            />
            <span style={{ fontSize: 14 }}>
              <span style={{ opacity: 0.4 }}>of</span>&nbsp;&nbsp;
              {Math.ceil(props.numPages)}
            </span>
            <Button
              style={{ marginLeft: 15, marginRight: 60 }}
              onClick={() => {
                if (props.pageNum < Math.ceil(props.numPages) - 1) {
                  props.setPageNum(props.pageNum + 1);
                }
              }}
            >
              Next
            </Button>
            <PaginationNumberInput
              style={{ marginRight: 10, width: 70 }}
              value={Math.ceil(props.numRows)}
              onBlur={(val) => {
                props.setNumRows(parseInt(val));
              }}
              onEnter={(val) => {
                props.setNumRows(parseInt(val));
              }}
            />
            <span style={{ fontSize: 14 }}>Per page</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SortableColumnsTable;
