// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./login_bg2.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_login__jYuYf {
  width: 100vw;
  height: 100vh;
  z-index: 20000;
}
.login_background__LZJqC {
  /* background: url("../../../login_bg2.jpg"); */
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  width: 100vw;
  height: 100vh;
  animation: login_scaleInSmall__NnnE\\+ 0.8s forwards;
}

.login_overlay__fUaEF {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: login_scaleIn__-ms7q 0.8s forwards;
}
.login_logo__r\\+Hjh {
  width: 350px;
  margin-bottom: 50px;
}
@keyframes login_scaleIn__-ms7q {
  0% {
    transform: scale(1.2);
    opacity: 0.4;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes login_scaleInSmall__NnnE\\+ {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/login/login.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,cAAc;AAChB;AACA;EACE,+CAA+C;EAC/C,mDAAkC;EAClC,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,mDAAqC;AACvC;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,6CAAgC;AAClC;AACA;EACE,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE;IACE,qBAAqB;IACrB,YAAY;EACd;EACA;IACE,mBAAmB;IACnB,UAAU;EACZ;AACF;AACA;EACE;IACE,YAAY;EACd;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".login {\n  width: 100vw;\n  height: 100vh;\n  z-index: 20000;\n}\n.background {\n  /* background: url(\"../../../login_bg2.jpg\"); */\n  background: url(\"./login_bg2.jpg\");\n  background-size: cover;\n  width: 100vw;\n  height: 100vh;\n  animation: scaleInSmall 0.8s forwards;\n}\n\n.overlay {\n  width: 100vw;\n  height: 100vh;\n  background: rgba(0, 0, 0, 0.6);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  animation: scaleIn 0.8s forwards;\n}\n.logo {\n  width: 350px;\n  margin-bottom: 50px;\n}\n@keyframes scaleIn {\n  0% {\n    transform: scale(1.2);\n    opacity: 0.4;\n  }\n  100% {\n    transform: scale(1);\n    opacity: 1;\n  }\n}\n@keyframes scaleInSmall {\n  0% {\n    opacity: 0.4;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `login_login__jYuYf`,
	"background": `login_background__LZJqC`,
	"scaleInSmall": `login_scaleInSmall__NnnE+`,
	"overlay": `login_overlay__fUaEF`,
	"scaleIn": `login_scaleIn__-ms7q`,
	"logo": `login_logo__r+Hjh`
};
export default ___CSS_LOADER_EXPORT___;
