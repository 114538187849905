/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Typography,
  FormGroup,
  TextField,
  Modal,
  Card,
  Button,
  Snackbar,
  Alert,
  CardHeader
} from "@mui/material"

import UserAPIGetters from "../../utils/fetch/users/userGetters";
import userPutters from "../../utils/fetch/users/userPutters";

import {
  setActivePage
} from '../../reducers/appSettingsSlice';
import "react-confirm-alert/src/react-confirm-alert.css";
import MUIDataTable from "mui-datatables"
import {modalStyle} from "../../theme/theme";
import { useForm } from 'react-hook-form'
import CenteredSpinner from "../../organisms/CenteredSpinner";

export const Users =  () => {

  const userGetters = new UserAPIGetters();
  const userPutterAPI = new userPutters();

  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
  const dispatch = useDispatch();
  const [hasDownloaded, setHasDownloaded] = useState(false);
  const [activeEditId, setActiveEditId] = useState(-1);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false)
  const [submitting, setSubmitting] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [users, setUsers] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const ActivePage = useSelector((state) => state.ActivePage);

  /* API GETTERS */
  const getUsers = async () => {
    const data = await userGetters.getFCUsers();
    setHasDownloaded(true);
    setUsers(data);
  };

  /* NOTE: The following methods retrieve roles and permissions, but these features aren't currently working */
  /* TODO: Once roles and permissions are active, these methods may be used to pull them into the forms */
  /*const getRoleList = async () => {
    const roles = await userGetters.getRoleList();
    setRoles(roles);
  };

  const getPermissionList = async () => {
    const permissionList = await userGetters.getPermissionList();
    setPermissionList(permissionList);
  };*/

  /* FUNCTIONS */
  const toggleUserActive = (user, value) => {
    userPutterAPI.toggleUserActive(user, value).then(() => {
      setHasDownloaded(false);
      getUsers();
    })
  }

  const submitNewUser = async (data) => {
    setSubmitting(true)
    /* Manually add role id since roles are not currently functional */
    let newUserData = {...data, role_id: 25}
    const submitToApi = await userPutterAPI.createFCUser(newUserData);
    if(submitToApi.ok){
      setShowAddModal(false)
      reset();
      setSubmitting(false)
      getUsers();
    }
  };

  const submitUserEdit = async (data) => {
    setShowEditModal(false);
    const finalData = {...data, userid: activeEditId.valueOf()}
    const updatedUser = await userPutterAPI.updateUser(finalData);
    if (updatedUser.success){
      reset();
      setActiveEditId(-1)
      getUsers();
      setSnackbarOpen(true)
    } else {
      reset();
      setActiveEditId(-1)
      setShowErrorModal(true)
    }
  };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

  const userForm = (
      <>
        <TextField
            {...register("first", {pattern: /\p{L}+/u})}
            name="first"
            label="First"
            sx={{margin: '5px'}}
        />
        <TextField
            {...register("last", {pattern: /\p{L}+/u})}
            name="last"
            label="Last"
            sx={{margin: '5px'}}
        />
        <TextField
            {...register("username", {required: true})}
            name="username"
            label="Username"
            sx={{margin: '5px'}}
            required
            error={(errors?.username) ? true : false}
            helperText={(errors?.username) ? "Please enter a username": ""}
        />
        <TextField
            {...register('email',
                { required: true,
                  pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                })}
            name="email"
            label="Email"
            required
            sx={{margin: '5px'}}
            error={(errors?.email) ? true : false}
            helperText={(errors?.email) ? "This field is required": ""}
        />
        <TextField
            type={"select"}
            placeholder="Role - Super Admin"
            disabled
            style={{"& .MuiInputBase-input.Mui-disabled": {WebkitTextFillColor: "#5d5d5d", }, margin: '5px'}}
        />
      </>
  )

  const dataTableColumns = [
    { name: "id", label: "ID", options: {sort: true, filter: true}},
    { name: "username", label: "Username", options: {sort: true, filter: true}},
    { name: "first", label: "First", options: {sort: true, filter: true}},
    { name: "last", label: "Last", options: {sort: true, filter: true}},
    { name: "email", label: "Email", options: {sort: true, filter: true}},
    { name: "active", label: "Active", options: {
      sort: true,
      filter: true,
      customBodyRenderLite: (dataIndex) => {
        /* This boolean will be true if user is active */
        let user = users[dataIndex]
        let buttonSwitch = (user.active === 1)
        let buttonColor = buttonSwitch ? "#CA111E" : "#2E2E2E"
        let buttonText = buttonSwitch ? "deactivate" : "activate"
        return (
            <Button
              style={{backgroundColor: buttonColor, color: "#fff", fontSize: "11px"}}
              onClick={() => {
                if( window.confirm(`Do you wish to ${buttonText} ${user.first} ${user.last} (${user.email})?`)){
                  if (buttonText === "deactivate"){
                    toggleUserActive(user.id, 0)
                  } else if (buttonText === "activate"){
                    toggleUserActive(user.id, 1)
                  }
                }
              }}
            >{buttonText}</Button>
        )
      }
      }},
    { name: "role_name", label: "Role Name", options: {sort: true, filter: true}},
    { name: "role_id", label: "Role ID", options: {sort: true, filter: true}},
    { name: "Edit", label: "Edit", options: {
      sort: false,
      filter: false,
      customBodyRenderLite: (dataIndex) => {
        let user = users[dataIndex]
        return (
            <Button
              onClick={() => {
                setEditValues({
                        first: user.first,
                        last: user.last,
                        username: user.username,
                        email: user.email,
                        userid: user.id
                })
                setActiveEditId(user.id)
                setShowEditModal(true)
              }}
              style={{color: "#fff", backgroundColor: "#2E2E2E", fontSize: "11px"}}
            >Edit</Button>
        )
      }
    }},
  ]

  /* USE EFFECTS */
  useEffect(() => {
    if (ActivePage !== "blckline_users") {
      getUsers();
      dispatch(setActivePage("blckline_users"));
    }
  }, [ActivePage]);

  useEffect(() => {
    /*This was modified from the addEmailModal.js file, and follows the same pattern filling in the RHF form elements*/
    const fields = ["first", "last", "username", "email"]

    if (activeEditId === -1){
      fields.forEach(field => {
        setValue(field, "")
      })
    }

    if (activeEditId !== -1){
      fields.forEach(field =>{
        setValue(field, editValues[field])
      })
    }
  }, [activeEditId])

  return (
    <Box sx={{ marginTop: {xs: "10px", md: "inherit"}}}>
      <Box sx={{
        display: "grid",
        gridTemplateColumns: {xs: "1fr", sm: "3fr 1fr"},
        gridColumnGap: "20px",
        gridRowGap: "20px"
      }}>
        <Card sx={{paddingLeft: "8px", paddingRight: "8px", color: "#fff"}}>
        <CardHeader title={"All Users"}/>
          { (!hasDownloaded) ? <CenteredSpinner /> :
          <Box sx={{display: "flex", flexDirection: "column"}}>
              <Button sx={{
                  marginBottom: "5px",
                  backgroundColor: "#2E2E2E",
                  color: "#fff",
                  display: {xs: "inline", md: "none"}}}
                onClick={() => {setShowAddModal(true)}}>Add User
              </Button>
                <MUIDataTable
                  data={users}
                  columns={dataTableColumns}
                  options={{
                    filterType: "textField",
                    selectableRows: "none",
                    responsive: "standard",
                    print: false,
                    download: false,
                  }}
                />
         </Box>
          }
        </Card>
        <Card sx={{display: {xs: "none", md: "inline"}}}>
            <CardHeader title={"Add User"}/>
          {submitting ? <CenteredSpinner /> :
              <FormGroup sx={{padding: "8px"}}>
                {userForm}
                <Button
                    type="submit"
                    style={{color: "#fff", backgroundColor: "#2e2e2e", margin: "5px"}}
                    onClick={handleSubmit(submitNewUser)}
                >
                  Submit
                </Button>
              </FormGroup>
          }
        </Card>
      </Box>
      <Modal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <Box sx={modalStyle}>
          <Typography variant="h4" align="center">Edit User</Typography>
          <FormGroup>
            {userForm}
            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
              <Button
                  type="submit"
                  style={{color: "#fff", backgroundColor: "#2e2e2e", margin: "5px"}}
                  onClick={handleSubmit(submitUserEdit)}
              >
                Submit
              </Button>
              <Button
                  onClick={() => {
                    setActiveEditId(-1)
                    setEditValues({})
                    setShowEditModal(false)}
                  }
                  style={{color: "#fff"}}
              >
                Cancel
              </Button>
            </Box>
          </FormGroup>
        </Box>
      </Modal>
    <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
    >
        <Alert
            onClose={handleSnackbarClose}
            severity={"success"}
            variant={"filled"}
            sx={{width: "100%"}}
        >
            User Modified Successfully!
        </Alert>
    </Snackbar>
      <Modal open={showErrorModal} onClose={() => setShowErrorModal(false)}>
       <Box sx={modalStyle}>
         <Typography variant="h4" align="center">Error</Typography>
         <Typography sx={{marginBottom: "16px"}}>
           There was a server error - please check your inputs and try again.
           If problem persists, contact support.
         </Typography>
         <Button
             onClick={() => setShowErrorModal(false)}
             sx={{width: "100%", backgroundColor: "#2e2e2e", color: "primary.contrastText"}}
         >OK</Button>
       </Box>
      </Modal>
      <Modal open={showAddModal}>
        <Box sx={modalStyle}>
            <Typography variant="h4" align="center">Add User</Typography>
            <FormGroup sx={{padding: "8px"}}>
                {userForm}
                <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <Button
                        type="submit"
                        style={{color: "#fff", backgroundColor: "#2e2e2e", margin: "5px"}}
                        onClick={handleSubmit(submitNewUser)}
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={() => {
                            setShowAddModal(false)}
                        }
                        style={{color: "#fff"}}
                    >
                        Cancel
                    </Button>
                </Box>
            </FormGroup>
        </Box>
      </Modal>
    </Box>
  );
};

export default Users;