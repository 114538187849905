// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch_track__7o3gy {
  height: 14px;
  background: var(--medium);
  width: 38px;
  border-radius: 50px;
  position: relative;
}
.switch_circle__QNL9h {
  position: absolute;
  left: -3px;
  top: -2.5px;
  height: 19px;
  width: 19px;
  border-radius: 50px;
  background-color: black;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.switch_circle_on__tlp7U {
  background-color: white;
  transform: translateX(23px);
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/atoms/switch/switch.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,WAAW;EACX,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,uBAAuB;EACvB,0CAA0C;EAC1C,yDAAyD;EACzD,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,uBAAuB;EACvB,2BAA2B;EAC3B,YAAY;AACd","sourcesContent":[".track {\n  height: 14px;\n  background: var(--medium);\n  width: 38px;\n  border-radius: 50px;\n  position: relative;\n}\n.circle {\n  position: absolute;\n  left: -3px;\n  top: -2.5px;\n  height: 19px;\n  width: 19px;\n  border-radius: 50px;\n  background-color: black;\n  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);\n  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.circle_on {\n  background-color: white;\n  transform: translateX(23px);\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"track": `switch_track__7o3gy`,
	"circle": `switch_circle__QNL9h`,
	"circle_on": `switch_circle_on__tlp7U`
};
export default ___CSS_LOADER_EXPORT___;
