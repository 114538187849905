import React, {useState, useEffect} from "react";

import BitMgmt from "../utils/fetch/bitmgmt/bitmgmt";

import {
  fc_molecules,
  fc_atoms
} from '../uiSrc';

export const ChangeLocationPortfolioModal = (props) => {

  const {
    Card,
    NewModal
  } = fc_molecules;

  const {
    Spinner,
    BlockDataItem,
    Button,
  } = fc_atoms;

  const {account, isVisible, closeModal, updateAccounts} = props;
  const [dataForUpdate, setDataForUpdate] = useState({
    newLocPortId: null,
    accountId: null
  });
  const [availablePortfolios, setAvailablePortfolios] = useState([])
  const [currentPortfolioName, setCurrentPortfolioName] = useState('');
  const [modalSuccessText, setModalSuccessText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const bitMgmtAPI = new BitMgmt();

  const setNewLocationPortfolioId = (newId) => {
    const tmp = {...dataForUpdate};
    tmp.newLocPortId = newId;
    setDataForUpdate(tmp);
  }

  

  const doLocPortUpdate = async () => {
    if(dataForUpdate.newLocPortId && dataForUpdate.accountId) {
      const doUpdate = await bitMgmtAPI.updateAccountPortfolioLocation(dataForUpdate.newLocPortId, dataForUpdate.accountId);
      if(doUpdate.success) {
        // Show success
        // Trigger data refresh
        // Trigger close
        setModalSuccessText(`Successfully moved ${dataForUpdate.newLocPortId} to ${dataForUpdate.accountId}`);
        updateAccounts(dataForUpdate.newLocPortId);
        closeModal(false);
      } else {
        // Show error
        setModalSuccessText(`Failed to move ${dataForUpdate.newLocPortId} to ${dataForUpdate.accountId}`);
      }
    } else {
      // This should be unreachable.
      alert(`You've reached an unreachable state! How did you get here?`);
    }
  }
  useEffect(() => {
    const loadPortfolioChangeInterfaceData = async () => {
      // If account is already loaded, this action cannot be completed
      if(account.loaded) {
        // Alert failure
        alert('Account has already been loaded! How did you get here?');
        return false;
      }
      if(!account.account_id) {
        return;
      }
      setIsLoading(true);
      // Populate the state variable with modal contents
        // Relavent account info in .account
        // all (portfolio_location_id and portfolio_location_name) from the same organization to which the account belongs
      const availablePorts = await bitMgmtAPI.getOrganizationByLocationPortfolioId(account.location_portfolio_id);
      setAvailablePortfolios(availablePorts);
      // I'm sorry Jake, it was just shorter this way. 
      const currentPortName = [...availablePorts]
        .filter((i) => {
          return String(i.location_portfolio_id) === String(account.location_portfolio_id)
        })
        .reduce((p,c) => p + String(c.location_portfolio_name),'');
      const tmp = {...dataForUpdate};
      tmp.accountId = account.account_id;
      setCurrentPortfolioName(currentPortName);
      // This is a state variable sent with the AJAX. It starts with the accountId set, so all it needs is the result of the org pull
      setDataForUpdate(tmp);
      setIsLoading(false);
      return true;
    };
    loadPortfolioChangeInterfaceData();
  }, [account.account_id]);
  return (
    <NewModal visible={isVisible} onHide={() => closeModal(false)}>
      <Card title={`${account.full_name}`} style={{ width: '700px', height: '500px', overflowY: 'scroll', margin: '0px 0px'}}>
        {
          modalSuccessText.length ? 
            <p>{modalSuccessText}</p>
          : 
            isLoading ?
              <div style={{ height: 300, width: "50%", display: `${isLoading ? 'block' : 'none'}` }} className="inline_center">
                <Spinner />
              </div>
            :
            <>
              <BlockDataItem title="Account">
                {account.account_id}
              </BlockDataItem>
              <BlockDataItem title="Current Portfolio" >
                {`${currentPortfolioName ? currentPortfolioName : ''}-${account.location_portfolio_id}`}
              </BlockDataItem>
              <BlockDataItem
                title="Available Portfolios"
                style={{
                  width: "100%",
                  maxWidth: "none",
                }}
              >
                <select name={`newLocationPortfolio`} onChange={(e) => setNewLocationPortfolioId(e.target.value)}>
                  <option selected={true} disabled={true}>{ availablePortfolios.length ? 'Choose a New Portfolio' : 'There are no portfolios available'}</option>
                  {
                    availablePortfolios.map((i) => {
                      return (
                        <option disabled={String(i.location_portfolio_id) === String(account.location_portfolio_id)} value={i.location_portfolio_id} key={i.location_portfolio_id}>{i.location_portfolio_id} - {i.location_portfolio_name}</option>
                      )
                    })
                  }
                </select>
              </BlockDataItem>
              <Button disabled={!(dataForUpdate.newLocPortId && dataForUpdate.accountId)} onClick={() => doLocPortUpdate()}>Update</Button>&nbsp;&nbsp;&nbsp;
            </>
        }
        <Button className={`button_red`} onClick={() => closeModal(false)}>Cancel</Button>
      </Card>
    </NewModal>
  )
}

export default ChangeLocationPortfolioModal;