// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activeDuplicates_input__1jQgh {
  background: #212121;
  border: 1pt solid rgba(31, 31, 31, 0);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  color: rgb(189, 189, 189);
  height: 30px;
  font-size: 13px;
  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);
}
.activeDuplicates_input__1jQgh:focus {
  outline: none;
}

.activeDuplicates_header__sFFmm {
  background: var(--darker);
  height: 50px;
  width: calc(100% - 5px);
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-left: 10px;
  font-size: 15px;
  font-weight: 700;
}

.activeDuplicates_button__tMs5v {
  background: #1f1f1f;
  border: 1pt solid rgba(31, 31, 31, 0);
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  /* margin-right: 5px; */
  border-radius: 2px;
  color: rgb(189, 189, 189);
  height: 30px;
  font-size: 14px;
  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/activeDuplicates/activeDuplicates.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qCAAqC;EACrC,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,gDAAgD;AAClD;AACA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,qCAAqC;EACrC,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,gDAAgD;EAChD,eAAe;AACjB","sourcesContent":[".input {\n  background: #212121;\n  border: 1pt solid rgba(31, 31, 31, 0);\n  padding-left: 10px;\n  padding-right: 10px;\n  border-radius: 4px;\n  color: rgb(189, 189, 189);\n  height: 30px;\n  font-size: 13px;\n  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);\n}\n.input:focus {\n  outline: none;\n}\n\n.header {\n  background: var(--darker);\n  height: 50px;\n  width: calc(100% - 5px);\n  display: flex;\n  /* justify-content: center; */\n  align-items: center;\n  padding-left: 10px;\n  font-size: 15px;\n  font-weight: 700;\n}\n\n.button {\n  background: #1f1f1f;\n  border: 1pt solid rgba(31, 31, 31, 0);\n  border: none;\n  padding-left: 10px;\n  padding-right: 10px;\n  padding-top: 5px;\n  /* margin-right: 5px; */\n  border-radius: 2px;\n  color: rgb(189, 189, 189);\n  height: 30px;\n  font-size: 14px;\n  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `activeDuplicates_input__1jQgh`,
	"header": `activeDuplicates_header__sFFmm`,
	"button": `activeDuplicates_button__tMs5v`
};
export default ___CSS_LOADER_EXPORT___;
