import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Card, CardHeader} from "@mui/material"
import CenteredSpinner from "../../organisms/CenteredSpinner";
import MUIDataTable from "mui-datatables"

// Fetch API
import CRMGetters from "../../utils/fetch/crm/crmGetters";

// Actions
import {
  setActivePage
} from '../../reducers/appSettingsSlice';

import { 
  setContacts,
} from '../../reducers/contactsSlice';


export const ContactsPage = () => {

  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);
  const contactResults = useSelector((state) => state.Contacts.contacts);
  const contactsHaveDownloaded = useSelector((state) => state.Contacts.meta.hasDownloaded);

  const dataTableColumns = [
        { name: "honorific", label: "Honorific", options: {sort: true, filter: true, sortThirdClickReset: true}},
        { name: "first", label: "First", options: {sort: true, filter: true, sortThirdClickReset: true}},
        { name: "middle", label: "Middle", options: {sort: true, filter: true, sortThirdClickReset: true}},
        { name: "last", label: "Last", options: {sort: true, filter: true, sortThirdClickReset: true}},
        { name: "suffix", label: "Suffix", options: {sort: true, filter: true, sortThirdClickReset: true}},
        { name: "created", label: "Created", options: {sort: true, filter: true, sortThirdClickReset: true}},
        { name: "updated", label: "Updated", options: {sort: true, filter: true, sortThirdClickReset: true}},
        { name: "id", label: "ID", options: {sort: true, filter: true}},
        { name: "Actions", options: {
            sort: false,
            filter: false,
            customBodyRenderLite: (dataIndex) => {
                let rowId = contactResults[dataIndex].id;
                return (
                    <Link to={`/contacts/contact/${rowId}`}>
                        <Button
                            sx={{fontSize: "11px"}}
                            color="secondary"
                            variant="contained"
                        >View</Button>
                    </Link>
                );
            }
        }},
    ]
  
  const hasDownloaded = useSelector(
    (state) => state.Contacts.meta.hasDownloaded
  );
  const dispatch = useDispatch();
  const crmGetters = new CRMGetters();

  const getInit = async () => {
    const data = await crmGetters.getContacts();
    dispatch(setContacts(data));
  };

  useEffect(() => {
    if (ActivePage !== "contacts") {
      dispatch(setActivePage("contacts"));
    }
    if (!hasDownloaded && !contactResults.length) {
      getInit();
    }
  },[hasDownloaded, contactResults]);

  return (
    <Box sx={{marginTop: {xs: "5em", md: "0px"},}}>
        <Box sx={{
            display: "grid",
            gridTemplateColumns:  "1fr",
        }}>
            <Card
              title="All Contacts"
              style={{ minHeight: "90vh", paddingRight: "10px", color: "#fff"}}
            >
                <CardHeader title={"All Contacts"}></CardHeader>
              {
                !contactsHaveDownloaded ?
                  <CenteredSpinner />
                :
                  <>
                      <Box sx={{paddingLeft: "16px"}}>
                          <MUIDataTable
                            data={contactResults}
                            columns={dataTableColumns}
                            options={{
                                filterType: 'textField',
                                selectableRows: "none",
                                responsive: "standard",
                                print: false,
                          }}
                          />
                      </Box>
                  </>
              }
            </Card>
        </Box>
    </Box>
  );
};

export default ContactsPage;