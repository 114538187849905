// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textInput_input__2d\\+i4 {
  background: #212121;
  border: 1pt solid rgba(31, 31, 31, 0);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  color: rgb(189, 189, 189);
  height: 30px;
  font-size: 13px;
  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);
}
.textInput_input__2d\\+i4:focus {
  outline: none;
}
.textInput_clearButton__bq1xo {
  position: absolute;
  right: -20px;
  top: 6px;
  opacity: 0.3;
}
.textInput_clearButton__bq1xo:hover {
  cursor: pointer;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/atoms/inputs/textInput/textInput.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qCAAqC;EACrC,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,gDAAgD;AAClD;AACA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,QAAQ;EACR,YAAY;AACd;AACA;EACE,eAAe;EACf,UAAU;AACZ","sourcesContent":[".input {\n  background: #212121;\n  border: 1pt solid rgba(31, 31, 31, 0);\n  padding-left: 10px;\n  padding-right: 10px;\n  border-radius: 4px;\n  color: rgb(189, 189, 189);\n  height: 30px;\n  font-size: 13px;\n  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);\n}\n.input:focus {\n  outline: none;\n}\n.clearButton {\n  position: absolute;\n  right: -20px;\n  top: 6px;\n  opacity: 0.3;\n}\n.clearButton:hover {\n  cursor: pointer;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `textInput_input__2d+i4`,
	"clearButton": `textInput_clearButton__bq1xo`
};
export default ___CSS_LOADER_EXPORT___;
