import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Box, Typography, Card, CardHeader, TextField, Button } from "@mui/material"
import MUIDataTable from "mui-datatables"

import {
  fc_atoms
} from '../../uiSrc';

import {
  setActivePage
} from '../../reducers/appSettingsSlice';

import BitMgmt from "../../utils/fetch/bitmgmt/bitmgmt";

import {
  setPortfolioSearchStringPort,
  setPortfolioSearchStringPortName,
  setPortfolioSearchStringOrgName,
} from '../../reducers/portfoliosSlice';

export const Portfolios = () => {

  const {
    Spinner,
  } = fc_atoms;

  const [portfolioMatches, setPortfolioMatches] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const dispatch = useDispatch();
  // Redux Selectors
  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);
  const searchStringPort = useSelector((state) => state.BitPortfolios.meta.searchStringPort);
  const searchStringPortName = useSelector((state) => state.BitPortfolios.meta.searchStringPortName);
  const searchStringOrgName = useSelector((state) => state.BitPortfolios.meta.searchStringOrgName);

  // 
  const runPortfolioSearch = async () => {
    setIsSearching(true)
    const bitMgmtAPI = new BitMgmt();
    bitMgmtAPI.getPortfolios(
      searchStringPort,
      searchStringPortName,
      searchStringOrgName
    )
    .then((data) => {
      setIsSearching(false);
      const results =
        (
          Array.isArray(data) && data.length > 0 && Array.isArray(data[0])
          ? 
            data[0]
          : 
            []
        )
        setPortfolioMatches(results);
    });
  };

  useEffect(() => {
    if (ActivePage !== "portfolios") {
      dispatch(setActivePage("portfolios"));
      if(searchStringPort.length || searchStringPortName.length || searchStringOrgName.length ) {
        runPortfolioSearch();
      }
    }
  });

  const dataTableColumns = [
    { name: "name", label: "Name", options: {sort: true, filter: true}},
    { name: "portfolio_id", label: "Portfolio ID", options: {sort: true, filter: true}},
    { name: "organization_name", label: "Organization Name", options: {sort: true, filter: true}},
    { name: "buy_rate", label: "Buy Rate", options: {sort: true, filter: true}},
    { name: "cutoff_date", label: "Cutoff Date", options: {
      sort: true,
      filter: true,
      customBodyRenderLite: (dataIndex) => {
        const date = portfolioMatches[dataIndex].cutoff_date
        if (date !== "" && date !== undefined && date !== null ){
          return moment(date).format("YYYY/MM/d - h:mm A")
        } else {
          return ""
        }
      }}},
    { name: "moment_created", label: "Moment Created", options: {
      sort: true,
      filter: true,
      customBodyRenderLite: (dataIndex) => {
        const date = portfolioMatches[dataIndex].moment_created
        return moment(date).format("YYYY/MM/d - h:mm A")
      }}},
    { name: "moment_updated", label: "Moment Updated", options: {
      sort: true,
      filter: true,
      customBodyRenderLite: (dataIndex) =>{
        const date = portfolioMatches[dataIndex].moment_updated
        return moment(date).format("YYYY/MM/d - h:mm A")
      }}},
    { name: "accounts", label: "Accounts", options: {sort: true, filter: true}},
    { name: "import_id", label: "Import ID", options: {sort: true, filter: true}},
    { name: " ", label: "Action", options: {
      sort: false,
      filter: false,
      customBodyRenderLite: () => {
        return (
            <Button
                sx={{backgroundColor: "#212121", color: "#fff", fontSize: "12px"}}
                variant="contained"
                onClick={() => alert("Coming Soon - Remind Brian")}
            >Summary</Button>
        )
      }}},
  ]

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 13) {
      runPortfolioSearch()
    }
  };

  return (
    <Box>
        <Card sx={{color: "#fff", padding: "8px", minHeight: "500px"}}>
          <CardHeader title="Portfolios" />
          <Box sx={{marginTop: "8px", marginBottom: "8px", display: "flex", flexDirection: {xs: "column", md: "row"}}}>
            <TextField
              name="portfolioId"
              label="Portfolio ID"
              onChange={(event) => dispatch(setPortfolioSearchStringPort(event.target.value))}
              onKeyDown={handleKeyDown}
              sx={{marginRight: "8px"}}
            />
            <TextField
              name="portfolioName"
              label="Portfolio Name"
              onChange={(event) => dispatch(setPortfolioSearchStringPortName(event.target.value))}
              onKeyDown={handleKeyDown}
              sx={{marginRight: "8px"}}
            />
            <TextField
              name="organizationName"
              label="Organization Name"
              onChange={(event) => dispatch(setPortfolioSearchStringOrgName(event.target.value))}
              onKeyDown={handleKeyDown}
              sx={{marginRight: "8px"}}
            />
            {/*<TextField
              name="filter"
              label="Filter"
              onChange={(event) => dispatch(setBitPortfoliosFilterString(event.target.value))}
              onKeyDown={handleKeyDown}
              sx={{marginRight: "8px"}}
            />*/}
          </Box>
          {isSearching ? (
            <div
              style={{
                width: "100%",
                height: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          ) : portfolioMatches.length ? (
              <>
                <MUIDataTable
                  data={portfolioMatches}
                  columns={dataTableColumns}
                  options={{
                    filterType: "textField",
                    selectableRows: "none",
                    responsive: "standard",
                    print: false,
                    download: false,
                  }}
                />
              </>

          ) : (
              <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "300px",
                    width: "100%"
                  }}>
                <Typography>No Data Found</Typography>
              </Box>
          )}
        </Card>
    </Box>
  );
};

export default Portfolios;