// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolioFile_button__QRbAt {
  background: #1f1f1f;
  border: 1pt solid rgba(31, 31, 31, 0);
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  border-radius: 2px;
  color: rgb(189, 189, 189);
  height: 30px;
  font-size: 14px;
  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.portfolioFile_list__ZRnfG {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5;
}

.portfolioFile_footer__R4GgC {
  font-weight: bold;
}

.portfolioFile_footer__R4GgC td {
  border-top: 1px solid var(--medium);
}

.portfolioFile_number__604k4 {
  display: block;
  text-align:right;
}
`, "",{"version":3,"sources":["webpack://./src/pages/portfolioFile/portfolioFile.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qCAAqC;EACrC,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,gDAAgD;EAChD,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".button {\n  background: #1f1f1f;\n  border: 1pt solid rgba(31, 31, 31, 0);\n  border: none;\n  padding-left: 10px;\n  padding-right: 10px;\n  padding-top: 5px;\n  border-radius: 2px;\n  color: rgb(189, 189, 189);\n  height: 30px;\n  font-size: 14px;\n  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);\n  cursor: pointer;\n}\n\n.list {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-bottom: 5;\n}\n\n.footer {\n  font-weight: bold;\n}\n\n.footer td {\n  border-top: 1px solid var(--medium);\n}\n\n.number {\n  display: block;\n  text-align:right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `portfolioFile_button__QRbAt`,
	"list": `portfolioFile_list__ZRnfG`,
	"footer": `portfolioFile_footer__R4GgC`,
	"number": `portfolioFile_number__604k4`
};
export default ___CSS_LOADER_EXPORT___;
