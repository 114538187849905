import React from "react"
import { Modal, Box, Typography } from '@mui/material'


/*Had to duplicate the modalStyle from the theme as its not importable from the theme file for some reason*/
/*TODO: fix the bug preventing import of the modalStyles here */
export const localModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 390,
    bgcolor: '#333333',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    color: "#fff",
    zIndex: 100,
    overflowX: "auto",
    maxHeight: "95%"
}

const calcPercentage = (x, percentage) => {
    const factor = percentage / 100
    const finalCalc = (x * factor) % 100
    return finalCalc.toFixed(2)
}

const statsModal = (props) => {
    const { modalOpen, onClose, data } = props;

    return (
        <Modal
            open={modalOpen}
            onClose={onClose}
        >
            <Box sx={localModalStyle}>
                <Typography variant="h4">Portfolio Statistics</Typography>
                <br/>
                <Box sx={{alignItems: "center"}}>
                    <Typography><strong>Total Records:</strong> {data.total}</Typography>
                    <Typography><strong>Missing SSN:</strong> {data.missing_ssn}/{data.total} ({calcPercentage(data.missing_ssn, data.total)}%)</Typography>
                    <Typography><strong>Missing Phone:</strong> {data.missing_phone}/{data.total} ({calcPercentage(data.missing_phone, data.total)}%)</Typography>
                    <Typography><strong>Missing Address:</strong> {data.missing_address}/{data.total} ({calcPercentage(data.missing_address, data.total)}%)</Typography>
                </Box>
            </Box>
        </Modal>
    )
}

export default statsModal;