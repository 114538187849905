import React, { useState } from "react";
import styles from "./login.module.css";
import Spinner from "../../atoms/spinner/spinner";
import doLoginCheck from "../../utils/fetch/auth/doLoginCheck";
import {ThemeProvider, TextField, FormGroup, Button} from "@mui/material"
import theme from "../../theme/theme";
import { useForm } from 'react-hook-form'

export const Login =  (props) => {
    const { register, handleSubmit} = useForm();
    const [loading, setLoading] = useState(false);
    const [userName, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const {setLoginInfo} = props;

    const onSubmit = () => {
        setLoading(true);
        doLoginCheck({setLoginInfo, setLoading, userName, password});
    };


    return (
      <ThemeProvider theme={theme}>
        <div className={styles.login}>
          <div className={styles.background}>
            <div className={styles.overlay}>
              <div className={styles.main_container}>
                <img className={styles.logo} src="/bit_text.png" alt="logo" />
                    <FormGroup>
                        <TextField
                            {...register('username')}
                            name="username"
                            label="Username"
                            sx={{
                                color: "#fff",
                                margin: "8px",
                                borderColor: "#fff"
                            }}
                            variant="standard"
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <TextField
                            {...register('password')}
                            name="password"
                            label="Password"
                            type="password"
                            sx={{
                                color: "#fff",
                                margin: "8px",
                                borderColor: "#fff"
                            }}
                            variant="standard"
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter"){
                                   onSubmit()
                                }
                            }}
                        />

                  <div
                    style={{
                      width: "calc(100%)",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      style={{
                        background: "transparent",
                        color: "white",
                        border: "1px solid white",
                        marginTop: "15px"
                      }}
                      onClick={handleSubmit(onSubmit)}

                    >
                      {loading ? <Spinner /> : "Submit"}
                    </Button>
                  </div>
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
      );
};

export default Login;