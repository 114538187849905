// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_page__V6Xsp {
  width: calc(100vw - 201px);
  height: 100vh;
  position: relative;
  background: rgba(0, 0, 0, 0.6);
}
.page_collapsed__WDzCw {
  width: calc(100vw - 70px);
}
@keyframes page_fadeIn__TGEnf {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/organisms/page/page.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,aAAa;EACb,kBAAkB;EAClB,8BAA8B;AAChC;AACA;EACE,yBAAyB;AAC3B;AACA;EACE;IACE,YAAY;EACd;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".page {\n  width: calc(100vw - 201px);\n  height: 100vh;\n  position: relative;\n  background: rgba(0, 0, 0, 0.6);\n}\n.collapsed {\n  width: calc(100vw - 70px);\n}\n@keyframes fadeIn {\n  0% {\n    opacity: 0.8;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `page_page__V6Xsp`,
	"collapsed": `page_collapsed__WDzCw`,
	"fadeIn": `page_fadeIn__TGEnf`
};
export default ___CSS_LOADER_EXPORT___;
