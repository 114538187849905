// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_header__aJTyX {
  width: calc(100vw - 201px);
  height: 50px;
  background: var(--dark);
  /* transition: width .2s cubic-bezier(0.785, 0.135, 0.15, 0.86); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 1px solid var(--darkest);
  font-size: 14px;
}
.header_collapsed__-t13y {
  width: calc(100vw - 61px);
}
.header_headerLeft__Fvaus {
  padding-left: 20px;
  font-size: 16px;
  font-weight: 500;
}
.header_headerRight__kSsi2 {
  padding-right: 20px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  transition: transform 0.2s;
}
.header_showToggle__YZasI {
  transform: translateX(-340px);
}
.header_slider_toggle__22i5q {
  background: var(--darkest);
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  transform: translateX(20px);
  opacity: 0.7;
}
.header_showToggle__YZasI .header_slider_toggle__22i5q {
  transform: translateX(30px);
}
.header_header_slide__0QoKq {
  position: absolute;
  right: -340px;
  top: 0;
  height: 100%;
  background: var(--darkest);
  display: flex;
  align-items: center;
  padding-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/organisms/header/header.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,YAAY;EACZ,uBAAuB;EACvB,kEAAkE;EAClE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,qCAAqC;EACrC,eAAe;AACjB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,0BAA0B;AAC5B;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,YAAY;AACd;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,MAAM;EACN,YAAY;EACZ,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".header {\n  width: calc(100vw - 201px);\n  height: 50px;\n  background: var(--dark);\n  /* transition: width .2s cubic-bezier(0.785, 0.135, 0.15, 0.86); */\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-left: 1px solid var(--darkest);\n  font-size: 14px;\n}\n.collapsed {\n  width: calc(100vw - 61px);\n}\n.headerLeft {\n  padding-left: 20px;\n  font-size: 16px;\n  font-weight: 500;\n}\n.headerRight {\n  padding-right: 20px;\n  position: relative;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  transition: transform 0.2s;\n}\n.showToggle {\n  transform: translateX(-340px);\n}\n.slider_toggle {\n  background: var(--darkest);\n  padding-left: 20px;\n  padding-right: 20px;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  transform: translateX(20px);\n  opacity: 0.7;\n}\n.showToggle .slider_toggle {\n  transform: translateX(30px);\n}\n.header_slide {\n  position: absolute;\n  right: -340px;\n  top: 0;\n  height: 100%;\n  background: var(--darkest);\n  display: flex;\n  align-items: center;\n  padding-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_header__aJTyX`,
	"collapsed": `header_collapsed__-t13y`,
	"headerLeft": `header_headerLeft__Fvaus`,
	"headerRight": `header_headerRight__kSsi2`,
	"showToggle": `header_showToggle__YZasI`,
	"slider_toggle": `header_slider_toggle__22i5q`,
	"header_slide": `header_header_slide__0QoKq`
};
export default ___CSS_LOADER_EXPORT___;
