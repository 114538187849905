import { configureStore } from '@reduxjs/toolkit';

// Refactored

// Clos Admin Mods
import ServerMonitor from "../src/reducers/serverMonitorSlice";

// FC Core
import QueryTool from "../src/reducers/queryToolSlice";
import AppSettings from '../src/reducers/appSettingsSlice';
import UserSessions from "../src/reducers/userSessionSlice";
import Contacts from "../src/reducers/contactsSlice";
import Campaigns from "../src/reducers/campaignsSlice";
import NotificationPrefs from "../src/reducers/notificationsSlice";
import NewUser from "../src/reducers/newUserSlice";

// Bit Mgmt Admin Mods
import Organizations from "../src/reducers/organizationsSlice"
import AccountSearch from "../src/reducers/accountSlice";
import BitPortfolios from "../src/reducers/portfoliosSlice";
import Locations from "../src/reducers/locationsSlice";
import LocationPortfolios from "../src/reducers/locationPortfoliosSlice";
import ActiveDuplicates from '../src/reducers/activeDuplicatesSlice';
import PortfolioFile from '../src/reducers/portfolioFile/portfolioFileSlice';

// Needs Refactor


// bit_mgmt

/*
SET_USER_SESSION_IS_AUTHENTICATED
      SET_USER_SESSION_PERMISSIONS
*/



export const store = configureStore({
  reducer: {
    QueryTool,
    NotificationPrefs,
    NewUser,
    BitOrganizations: Organizations,
    BitPortfolios,
    Locations,
    LocationPortfolios,
    Contacts,
    AccountSearch,
    UserSessions,
    ServerMonitor,
    AppSettings,
    ActiveDuplicates,
    Campaigns,
    PortfolioFile,
  },
});