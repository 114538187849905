// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkable_container__rZY\\+n {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 0px;
  margin-bottom: 10px;
  font-size: 13px;
}
.checkable_checkbox__\\+I8qG {
  background-color: var(--darkest);
  color: white;
  width: 17px;
  height: 17px;
  border-radius: 2px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkable_checkbox_checked__hFIQn {
}
.checkable_container__rZY\\+n span {
  line-height: 0px;
}
.checkable_checkbox__\\+I8qG svg {
  transform: translateY(1px);
}
.checkable_light__t-h6t .checkable_checkbox__\\+I8qG {
  background-color: var(--dark);
}
`, "",{"version":3,"sources":["webpack://./src/atoms/checkable/checkable.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,gCAAgC;EAChC,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;AACA;AACA;EACE,gBAAgB;AAClB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,6BAA6B;AAC/B","sourcesContent":[".container {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  line-height: 0px;\n  margin-bottom: 10px;\n  font-size: 13px;\n}\n.checkbox {\n  background-color: var(--darkest);\n  color: white;\n  width: 17px;\n  height: 17px;\n  border-radius: 2px;\n  margin-right: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.checkbox_checked {\n}\n.container span {\n  line-height: 0px;\n}\n.checkbox svg {\n  transform: translateY(1px);\n}\n.light .checkbox {\n  background-color: var(--dark);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `checkable_container__rZY+n`,
	"checkbox": `checkable_checkbox__+I8qG`,
	"checkbox_checked": `checkable_checkbox_checked__hFIQn`,
	"light": `checkable_light__t-h6t`
};
export default ___CSS_LOADER_EXPORT___;
