import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ArrowLeft, ArrowRight } from "react-feather";
import FCSpokesAPI from '../../utils/fetch/spokes/spokes';
import { Box } from "@mui/material"

import {
  setActivePage
} from '../../reducers/appSettingsSlice';

import {
  fc_molecules,
  fc_atoms
} from '../../uiSrc';
import { 
  setNotificationPrefs,
  setLocationList,
  setNotificationsPrefsToAdd,
  setSelectedTypes,
  setSpokesToRemove,
  setSubbedSpokes,
  removeSubbedSpokes,
} from "../../reducers/notificationsSlice";

export const NotificationServices =  (props) => {

  const {
    Card,
    Modal,
  } = fc_molecules;
  const {
    Button,
    Spinner,
    Checkable,
  } = fc_atoms;

  const spokesAPI = new FCSpokesAPI();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const locationList = useSelector((state) => state.NotificationPrefs.locationList);
  const notificationPrefs = useSelector((state) => state.NotificationPrefs.prefs);
  const locToAdd = useSelector((state) => state.NotificationPrefs.prefs.toAdd);
  const locToRemove = useSelector((state) => state.NotificationPrefs.prefs.toRemove);

  const {
    subbedSpokes,
    subbedTypes,
    unSubbedSpokes,
    digest
  } = notificationPrefs;

  const loadInitialState = async () => {
    if (!locationList.length) {
      setLoading(true);
      const locations = await spokesAPI.getSpokes();
      dispatch(setLocationList(locations));
    }
    const info = await spokesAPI.getUserNotificationSettings();
    //filter the location list for all unsubscribed spokes
    const unsubbed = [];
    locationList.map((i) => {
      if(!info.spokes.includes(i.location_id)){unsubbed.push(i.location_id)}
    });
    dispatch(setNotificationPrefs({
      digest: info.digest,
      subbedTypes: info.types,
      subbedSpokes: info.spokes,
      toAdd: [],
      toRemove: [],
      unSubbedSpokes: unsubbed,
      locationSubStatus: [],
    }));
    console.log(info);
    setLoading(false);
  };

  //gets the initial settings from API
  useEffect(() => {
    loadInitialState();
    dispatch(setActivePage("notification_services"));
  },[]);

  //hardcoding array here until they're pulled through API KS 12/16/21
  const serviceTypeArray = [
    {type_id: 4, type_name: 'Server offline'},
    {type_id: 1, type_name: 'CLOS offline'},
    {type_id: 2, type_name: 'MySQL offline'},
    {type_id: 3, type_name: 'Sybase offline'},
    {type_id: 5, type_name: 'System EOL soon'},
    {type_id: 6, type_name: 'Kill Switch Engaged'},
  ];
  
  const saveNotificationPreferences = async () => {
    setSubmitting(true);
    //getting just the type_ids
    const serviceTypeValueKeys = serviceTypeArray.reduce((acc,c) => {acc.push(c.type_id);return acc;},[]);
    // getting the types that aren't selected
    const unsubbedTypes = serviceTypeValueKeys.filter((type) => !subbedTypes.includes(type));
    await spokesAPI.updateNotificationPreferences(subbedSpokes,subbedTypes,digest,unSubbedSpokes,unsubbedTypes)
      .then((res) => {
        if(res) {
          setSubmitting(false);
        }
      });
  };

  return (
    <Box>
        <Card title="Notification subscriptions">
          {!loading ? (
            <>
              {" "}
              <div className="inline_left">
                <div style={{ width: 400 }}>
                  <h4 style={{ margin: 0, marginBottom: 5, fontWeight: 800 }}>
                    Daily digest
                  </h4>
                  <em style={{ fontSize: 14 }}>
                    Receive a daily email containing the status of all locations
                  </em>
                  <br />
                  <br />
                  <Checkable
                    title="Daily digest"
                    checked={digest}
                    onClick={() => {
                      const newPrefs = {...notificationPrefs};
                      newPrefs.digest = !digest;
                      dispatch(setNotificationPrefs(newPrefs));
                    }}
                  />
                  <br />
                  <hr
                    style={{
                      margin: "auto",
                      width: "auto",
                      marginLeft: 40,
                      marginBottom: 20,
                    }}
                  />
                  <h4 style={{ margin: 0, marginBottom: 5, fontWeight: 800 }}>
                    Event notifications
                  </h4>
                  <em style={{ fontSize: 14 }}>
                    Receive an email when one of the following events occurs.
                  </em>
                  <br />
                  <br />
                  {
                    serviceTypeArray.length ? serviceTypeArray.map((i) => {
                      return(
                        <Checkable
                          title={i.type_name}
                          checked={subbedTypes && subbedTypes.includes(i.type_id) ? true : false}
                          onClick={() => {
                            if(subbedTypes.includes(i.type_id)){
                              const types = [...subbedTypes];
                              types.splice(types.indexOf(i.type_id), 1);
                              dispatch(setSelectedTypes(types));
                            } else {
                              dispatch(setSelectedTypes([...subbedTypes, i.type_id]));
                            }
                          }}
                        />)
                      })
                    : null
                  }
                  <br />
                </div>
                <div style={{ width: "calc(100% - 440px)" }}>
                  <h4 style={{ margin: 0, marginBottom: 5, fontWeight: 800 }}>
                    Server subscriptions
                  </h4>
                  <em style={{ fontSize: 14 }}>
                    Servers for which you will receive event emails
                  </em>
                  <br />
                  <br />
                  <div
                    className="inline_between"
                    style={{ width: "calc(100%)" }}
                  >
                    <div
                      style={{ width: "calc(100% - 40px)", marginRight: 30 }}
                    >
                      <div
                        style={{
                          fontSize: 14,
                          color: "var(--red)",
                          width: "calc(100% + 20px)",
                        }}
                        className="inline_between"
                      >
                        Not subscribed
                        <span
                          style={{
                            fontSize: 14,
                            textAlign: "right",
                            color: "white",
                          }}
                          className="hoverable"
                          onClick={() => {
                            let toAdd = [];
                            if (!locToAdd.length) {
                              locationList.map((el) => {
                                if (
                                  !subbedSpokes.includes(el.location_id)
                                ) {
                                  toAdd.push(el.location_id);
                                }
                                return null;
                              });
                            }
                            dispatch(setNotificationsPrefsToAdd(toAdd));
                          }}
                        >
                          {locToAdd.length ? "Deselect all" : "Select all"}
                        </span>
                      </div>
                      <div
                        style={{
                          height: 350,
                          background: "var(--darker)",
                          padding: 10,
                          width: "100%",
                          overflowY: "scroll",
                          marginTop: 5,
                        }}
                      >
                        {locationList.map((el) => {
                          if (!subbedSpokes.includes(el.location_id)) {
                            return (
                              <Checkable
                                title={el.short_name.toUpperCase()}
                                checked={locToAdd.includes(el.location_id)}
                                onClick={() => {
                                  console.log(locationList);
                                  const toAdd = [...locToAdd];
                                  if (toAdd.includes(el.location_id)) {
                                    toAdd.splice(toAdd.indexOf(el.location_id), 1);
                                  } else {
                                    toAdd.push(el.location_id);
                                  }
                                  dispatch(setNotificationsPrefsToAdd(toAdd));
                                }}
                              />
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div
                      style={{
                        height: 350,
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Button
                        style={{ width: 100, marginBottom: 10 }}
                        onClick={() => dispatch(setSubbedSpokes([...subbedSpokes, ...locToAdd]))}
                      >
                        Add
                        <ArrowRight
                          className="icon"
                          style={{ transform: "translateY(3px)" }}
                        />
                      </Button>
                      <Button
                        style={{ width: 100 }}
                        onClick={() => dispatch(removeSubbedSpokes(locToRemove))}
                      >
                        <ArrowLeft
                          className="icon"
                          style={{ transform: "translateY(3px)" }}
                        />
                        Remove
                      </Button>
                    </div>
                    <div style={{ width: "calc(100% - 40px)", marginLeft: 10 }}>
                      <div
                        style={{
                          fontSize: 14,
                          color: "var(--green)",
                          width: "calc(100% + 20px)",
                        }}
                        className="inline_between"
                      >
                        Subscribed
                        <span
                          style={{
                            fontSize: 14,
                            textAlign: "right",
                            color: "white",
                          }}
                          className="hoverable"
                          onClick={() => {
                            let toRemove = [];
                            if (!locToRemove.length) {
                              locationList.map((el) => {
                                if (
                                  subbedSpokes.includes(el.location_id)
                                ) {
                                  toRemove.push(el.location_id);
                                }
                                return null;
                              });
                            }
                            dispatch(setSpokesToRemove(toRemove));
                          }}
                        >
                          {locToRemove.length ? "Deselect all" : "Select all"}
                        </span>
                      </div>
                      <div
                        style={{
                          height: 350,
                          background: "var(--darker)",
                          padding: 10,
                          width: "100%",
                          overflowY: "scroll",
                          marginTop: 5,
                        }}
                      >
                      { locationList.map((el) => {
                          if (subbedSpokes.includes(el.location_id)) {
                            return (
                              <Checkable
                                title={el.short_name.toUpperCase()}
                                checked={locToRemove.includes(el.location_id)}
                                onClick={() => {
                                  let toRemove = [...locToRemove];
                                  if (toRemove.includes(el.location_id)) {
                                    toRemove.splice(toRemove.indexOf(el.location_id), 1);
                                  } else {
                                    toRemove.push(el.location_id);
                                  }
                                  dispatch(setSpokesToRemove(toRemove));
                                }}
                              />
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  borderTop: "1px solid var(--darkest)",
                  paddingTop: 15,
                }}
                className="inline_center"
              >
                <Button
                  onClick={() => saveNotificationPreferences()}
                  disabled={submitting}
                  style={{textAlign: 'center', display: 'flex'}}
                >
                  {submitting ? <Spinner /> : "Save"}
                </Button>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "calc(100% - 20px)",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </div>
          )}
        </Card>
      <Modal visible={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Card title="Error" style={{ width: 350 }}>
          {errorModalText}
          <br />
          <br />
          <br />
          <div
            style={{
              display: "flex",
              width: "calc(100% - 20px)",
              justifyContent: "center",
            }}
          >
            <Button onClick={() => setShowErrorModal(false)}>Ok</Button>
          </div>
        </Card>
      </Modal>
    </Box>
  );
};

export default NotificationServices;