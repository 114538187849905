import React from "react";
import styles from "./permissionTable.module.css";

import {
  fc_atoms
} from '../../uiSrc';


const PermissionTable = (props) => {
  const {
    Checkable,
  } = fc_atoms;
  const changePermission = (permission_id, level) => {
    const thisPerm = props.userPermissions.filter(
      (u) => permission_id === u.permission_id
    );
    if (thisPerm.length) {
      const permissions = [...props.userPermissions].map((p) => {
        if (p.permission_id === permission_id) {
          p.permission_level = level;
        }
        return p;
      });
      props.changePermissions(permissions);
    } else {
      const newPerm = [...props.userPermissions];
      newPerm.push({ permission_id, permission_level: level });
      props.changePermissions(newPerm);
    }
  };
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th></th>
            <th>Inherited</th>
            <th>Read only</th>
            <th>Full</th>
          </tr>
        </thead>
        <tbody>
          {props.permissionList.map((el, i) => {
            const thisPerm = props.userPermissions.filter(
              (u) => el.id === u.permission_id
            );
            const data = thisPerm.map((u) => {
              return (
                <tr>
                  {el.feature}
                  <td>
                    <Checkable
                      light
                      checked={u.permission_level === 0}
                      style={{ margin: 0 }}
                      key={`${u.permission_id}_0}`}
                      onClick={() => changePermission(u.permission_id, 0)}
                      disabled
                    />
                  </td>
                  <td>
                    <Checkable
                      light
                      checked={u.permission_level === 1}
                      style={{ margin: 0 }}
                      key={`${u.permission_id}_1}`}
                      onClick={() => changePermission(u.permission_id, 1)}
                    />
                  </td>
                  <td>
                    <Checkable
                      light
                      checked={u.permission_level === 2}
                      style={{ margin: 0 }}
                      key={`${u.permission_id}_2}`}
                      onClick={() => changePermission(u.permission_id, 2)}
                    />
                  </td>
                </tr>
              );
            });
            if (data[0]) {
              return data;
            } else {
              return (
                <tr>
                  {el.feature}
                  <td>
                    <Checkable
                      light
                      checked={true}
                      style={{ margin: 0 }}
                      key={`${el.permissionId}_0}`}
                      onClick={() => {
                        changePermission(el.id, 0);
                      }}
                    />
                  </td>
                  <td>
                    <Checkable
                      light
                      checked={false}
                      style={{ margin: 0 }}
                      key={`${el.permissionId}_1}`}
                      onClick={() => changePermission(el.id, 1)}
                    />
                  </td>
                  <td>
                    <Checkable
                      light
                      checked={false}
                      style={{ margin: 0 }}
                      key={`${el.permissionId}_2}`}
                      onClick={() => changePermission(el.id, 2)}
                    />
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PermissionTable;
