// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_button__YLP5J {
  background: #1f1f1f;
  border: 1pt solid rgba(31, 31, 31, 0);
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 2px;
  color: rgb(189, 189, 189);
  height: 30px;
  font-size: 13px;
  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);
}
.button_button__YLP5J:hover {
  background: rgb(26, 26, 26);
  cursor: pointer;
}
.button_button__YLP5J:active {
  outline: none;
  opacity: 0.5;
}
.button_button__YLP5J:focus {
  outline: none;
}
.button_small__mU2ow {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-left: 5px;
  padding-right: 5px;
}
.button_grey__eOksD {
  background: #656565;
  box-shadow: none;
  color: white;
}
.button_red__7lVEA {
  background: var(--darkRed);
  color: white;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
  border: none;
}
.button_red__7lVEA:hover {
  background: #ac3a36;
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.4);
}
.button_button__YLP5J:disabled {
  opacity: 0.4;
}
`, "",{"version":3,"sources":["webpack://./src/atoms/button/button.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qCAAqC;EACrC,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,gDAAgD;AAClD;AACA;EACE,2BAA2B;EAC3B,eAAe;AACjB;AACA;EACE,aAAa;EACb,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,0BAA0B;EAC1B,YAAY;EACZ,0CAA0C;EAC1C,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,gDAAgD;AAClD;AACA;EACE,YAAY;AACd","sourcesContent":[".button {\n  background: #1f1f1f;\n  border: 1pt solid rgba(31, 31, 31, 0);\n  border: none;\n  padding-left: 10px;\n  padding-right: 10px;\n  border-radius: 2px;\n  color: rgb(189, 189, 189);\n  height: 30px;\n  font-size: 13px;\n  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);\n}\n.button:hover {\n  background: rgb(26, 26, 26);\n  cursor: pointer;\n}\n.button:active {\n  outline: none;\n  opacity: 0.5;\n}\n.button:focus {\n  outline: none;\n}\n.small {\n  height: 20px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-top: 0;\n  padding-left: 5px;\n  padding-right: 5px;\n}\n.grey {\n  background: #656565;\n  box-shadow: none;\n  color: white;\n}\n.red {\n  background: var(--darkRed);\n  color: white;\n  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);\n  border: none;\n}\n.red:hover {\n  background: #ac3a36;\n  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.4);\n}\n.button:disabled {\n  opacity: 0.4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button_button__YLP5J`,
	"small": `button_small__mU2ow`,
	"grey": `button_grey__eOksD`,
	"red": `button_red__7lVEA`
};
export default ___CSS_LOADER_EXPORT___;
