import React from "react";

import {
  fc_organisms,
  fc_molecules,
} from '../../uiSrc';


export const HubSpoke = () => {
  const {
    Page,
    Grid,
    Header,
  } = fc_organisms;
  
  const {
    Card,
  } = fc_molecules;


  return (
    <Page>
      <Header headerLeft={<span>Server config</span>} />

      {/* Show the Hub Spoke syncing interval */}
      <Grid gridSize="one">
        <Card title="Hub and Spoke Admin">

        </Card>
      </Grid>
    </Page>
  );
};

export default HubSpoke;


/* <Grid gridSize="one_three">
        <Card title="Global settings" style={{ width: 300 }}>
          {!loading ? (
            <>
              <BlockDataItem
                title="Sales data sync interval (ms)"
                style={{
                  width: "calc(100% - 40px)",
                  marginRight: 40,
                  maxWidth: "none",
                }}
              >
                <TextInput
                  // disabled={getCookie("AdminLevel") != "2"}
                  style={{ width: "100%" }}
                  value={sales}
                  whitelist="numeric"
                  onChange={(val) => {
                    const newMeta = Object.assign({}, Meta);
                    newMeta.intervals.sales_sync_interval = val;
                    // dispatch(genericAction(newMeta, "SET_META"));
                  }}
                />
              </BlockDataItem>
              <BlockDataItem
                title="Non-sales data sync interval (ms)"
                style={{
                  width: "calc(100% - 40px)",
                  marginRight: 40,
                  maxWidth: "none",
                }}
              >
                <TextInput
                  // disabled={getCookie("AdminLevel") != "2"}
                  style={{ width: "100%" }}
                  value={nonSales}
                  whitelist="numeric"
                  onChange={(val) => {
                    const newMeta = Object.assign({}, Meta);
                    newMeta.intervals.non_sales_sync_interval = val;
                    // dispatch(genericAction(newMeta, "SET_META"));
                  }}
                />
              </BlockDataItem>
              <BlockDataItem
                title="Status sync interval (ms)"
                style={{
                  width: "calc(100% - 40px)",
                  marginRight: 40,
                  maxWidth: "none",
                }}
              >
                <TextInput
                  // disabled={getCookie("AdminLevel") != "2"}
                  style={{ width: "100%" }}
                  value={status}
                  whitelist="numeric"
                  onChange={(val) => {
                    const newMeta = Object.assign({}, Meta);
                    newMeta.intervals.status_check_interval = val;
                    // dispatch(genericAction(newMeta, "SET_META"));
                  }}
                />
              </BlockDataItem>
              <Checkable
                title="Global sync enabled"
                checked={sync}
                // disabled={getCookie("AdminLevel") != "2"}
                onClick={(val) => {
                  const newMeta = Object.assign({}, Meta);
                  newMeta.syncEnabled = !sync;
                  // dispatch(genericAction(newMeta, "SET_META"));
                }}
              />
              <br />
              <div
                style={{ width: "calc(100% - 20px)" }}
                className="inline_center"
              >
                <Button
                  onClick={() => submit()}
                  // disabled={getCookie("AdminLevel") != "2"}
                >
                  {submitting ? <Spinner /> : "Submit"}
                </Button>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "calc(100% - 20px)",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </div>
          )}
        </Card>
      </Grid>
      <Modal visible={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Card title="Error" style={{ width: 350 }}>
          {errorModalText}
          <br />
          <br />
          <br />
          <div
            style={{
              display: "flex",
              width: "calc(100% - 20px)",
              justifyContent: "center",
            }}
          >
            <Button onClick={() => setShowErrorModal(false)}>Ok</Button>
          </div>
        </Card>
      </Modal> */