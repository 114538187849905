// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newmodal_modal_overlay__VAYzE {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6) !important;
  /* backdrop-filter: blur(10px); */
  width: 100vw;
  height: 100vh;
  z-index: 100001;
  transform: translateX(-200px);
  /* animation: fadeIn 0.1s forwards; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.newmodal_hidden__HCcEL {
  display: none;
}
.newmodal_is_full__dExgu {
  transform: translateX(0px);
}
.newmodal_light__8SZNY {
  background: rgba(116, 116, 116, 0.8) !important;
}
@keyframes newmodal_fadeIn__Z8Oht {
  0% {
    backdrop-filter: blur(0px);
    opacity: 0;
  }
  100% {
    backdrop-filter: blur(10px);
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/molecules/modal/newmodal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,yCAAyC;EACzC,iCAAiC;EACjC,YAAY;EACZ,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,qCAAqC;EACrC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,+CAA+C;AACjD;AACA;EACE;IACE,0BAA0B;IAC1B,UAAU;EACZ;EACA;IACE,2BAA2B;IAC3B,UAAU;EACZ;AACF","sourcesContent":[".modal_overlay {\n  position: absolute;\n  left: 0;\n  top: 0;\n  background: rgba(0, 0, 0, 0.6) !important;\n  /* backdrop-filter: blur(10px); */\n  width: 100vw;\n  height: 100vh;\n  z-index: 100001;\n  transform: translateX(-200px);\n  /* animation: fadeIn 0.1s forwards; */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.hidden {\n  display: none;\n}\n.is_full {\n  transform: translateX(0px);\n}\n.light {\n  background: rgba(116, 116, 116, 0.8) !important;\n}\n@keyframes fadeIn {\n  0% {\n    backdrop-filter: blur(0px);\n    opacity: 0;\n  }\n  100% {\n    backdrop-filter: blur(10px);\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_overlay": `newmodal_modal_overlay__VAYzE`,
	"hidden": `newmodal_hidden__HCcEL`,
	"is_full": `newmodal_is_full__dExgu`,
	"light": `newmodal_light__8SZNY`,
	"fadeIn": `newmodal_fadeIn__Z8Oht`
};
export default ___CSS_LOADER_EXPORT___;
