// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blockDataItem_item__zfGxK {
  font-size: 14px;
  margin-right: 50px;
  margin-bottom: 20px;
  max-width: 200px;
  word-wrap: "wrap";
  word-break: "break-word";
}
.blockDataItem_inline__A0zen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: none;
  text-align: left;
}
.blockDataItem_inline__A0zen .blockDataItem_top__EbM0a {
  margin-bottom: 0px !important;
  margin-right: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/atoms/blockDataItem/blockDataItem.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,6BAA6B;EAC7B,kBAAkB;AACpB","sourcesContent":[".item {\n  font-size: 14px;\n  margin-right: 50px;\n  margin-bottom: 20px;\n  max-width: 200px;\n  word-wrap: \"wrap\";\n  word-break: \"break-word\";\n}\n.inline {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  max-width: none;\n  text-align: left;\n}\n.inline .top {\n  margin-bottom: 0px !important;\n  margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `blockDataItem_item__zfGxK`,
	"inline": `blockDataItem_inline__A0zen`,
	"top": `blockDataItem_top__EbM0a`
};
export default ___CSS_LOADER_EXPORT___;
