import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Box, Card, CardHeader } from "@mui/material"
import MUIDataTable from "mui-datatables"
import BitMgmt from "../../utils/fetch/bitmgmt/bitmgmt";

import {
  fc_atoms
} from '../../uiSrc';


import {
  setActivePage
} from '../../reducers/appSettingsSlice';

export const Organizations = () => {
  const [organizations, setOrganizations] = useState([]);
  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);
  
  const dispatch = useDispatch();

  const bitMgmtAPI = new BitMgmt();


  /*TODO: As we consolidate the three code folders, use CenteredSpinner instead */
  /*NOTE: See Also the TODO on serverMonitor.js*/
  const {
    Spinner,
  } = fc_atoms;

  const organizationTableColumns = [
  {name: "name", label: "Name", options: {
    sort: true,
    filter: true,
    sortThirdClickReset: true}},
  {name: "description", label: "Description", options: {sort: false, filter: false}},
  {name: "phone", label: "Phone", options: {sort: false, filter: false}},
  {name: "created", label: "Created", options: {
    sort: true,
    filter: true,
    sortThirdClickReset: true,
    customBodyRenderLite: (dataIndex) =>{
      const createdTimestamp = organizations[dataIndex].created
      return moment(createdTimestamp).format("YYYY/MM/d h:mm A")
    }}},
  {name: "updated", label: "Updated", options: {
    sort: true,
    filter: true,
    customBodyRenderLite: (dataIndex) =>{
      const updatedTimestamp = organizations[dataIndex].updated
      return moment(updatedTimestamp).format("YYYY/MM/d h:mm A")
    }}},
  {name: "status", label: "Status", options: {
    sort: true,
    filter: true,
    filterType: 'dropdown',
    filterOptions: {
      renderValue: val => val === 1 ? "Active" : "Inactive"
    },
    customBodyRenderLite: (dataIndex) =>{
      if (organizations[dataIndex].status === 1){
        return "Active"
      } else {
        return "Inactive"
      }
    }}},
  ]

  useEffect(() => {
    if (ActivePage !== "organizations") {
      dispatch(setActivePage("organizations"));
      bitMgmtAPI.getOrganizations()
        .then((data) => setOrganizations(data));
    } 
  });

  return (
    <Box>
        <Card
          sx={{ minHeight: "calc(100vh - 150px)", padding: "8px", color: "#fff" }}
        >
          <CardHeader title={"Organizations"}/>
          {
            organizations.length ?
              <MUIDataTable
                data={organizations}
                columns={organizationTableColumns}
                options={{
                  filterType: "textField",
                  selectableRows: "none",
                  responsive: "standard",
                  print: false,
                  download: false
                }}
              />
            :
            <div
              className="inline_center"
              style={{ width: "calc(100% - 20px)", paddingTop: '15%' }}
            >
              <Spinner />
            </div>
          }
        </Card>
    </Box>
  );
};

export default Organizations;
