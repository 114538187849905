// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabView_tabView__0mAZC {
  width: 100%;
}
.tabView_tabContainer__AC0Tb {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.tabView_tab__zCBXV {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid var(--darkest);
  border-left: 1px solid var(--darkest);
  border-right: 1px solid var(--darkest);
  margin-right: 10px;
  padding: 8px 9px 25px 9px;
  background: var(--darkest);
  opacity: 0.5;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  font-size: 13px;
  height: 22px;
}
.tabView_tab__zCBXV:hover {
  cursor: pointer;
}
.tabView_tabActive__zwssy {
  opacity: 1;
  border-color: var(--darkest);
  opacity: 1;
}
.tabView_bottomSection__jsSGU {
  width: 100%;
  padding-top: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/templates/tabView/tabView.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,2BAA2B;EAC3B,uBAAuB;AACzB;AACA;EACE,2BAA2B;EAC3B,4BAA4B;EAC5B,oCAAoC;EACpC,qCAAqC;EACrC,sCAAsC;EACtC,kBAAkB;EAClB,yBAAyB;EACzB,0BAA0B;EAC1B,YAAY;EACZ,0CAA0C;EAC1C,eAAe;EACf,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,UAAU;EACV,4BAA4B;EAC5B,UAAU;AACZ;AACA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".tabView {\n  width: 100%;\n}\n.tabContainer {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n}\n.tab {\n  border-top-left-radius: 5px;\n  border-top-right-radius: 5px;\n  border-top: 1px solid var(--darkest);\n  border-left: 1px solid var(--darkest);\n  border-right: 1px solid var(--darkest);\n  margin-right: 10px;\n  padding: 8px 9px 25px 9px;\n  background: var(--darkest);\n  opacity: 0.5;\n  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);\n  font-size: 13px;\n  height: 22px;\n}\n.tab:hover {\n  cursor: pointer;\n}\n.tabActive {\n  opacity: 1;\n  border-color: var(--darkest);\n  opacity: 1;\n}\n.bottomSection {\n  width: 100%;\n  padding-top: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabView": `tabView_tabView__0mAZC`,
	"tabContainer": `tabView_tabContainer__AC0Tb`,
	"tab": `tabView_tab__zCBXV`,
	"tabActive": `tabView_tabActive__zwssy`,
	"bottomSection": `tabView_bottomSection__jsSGU`
};
export default ___CSS_LOADER_EXPORT___;
