import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { CSVLink } from "react-csv";
import { Box, Button, Card, CardHeader } from "@mui/material"
import MUIDataTable from "mui-datatables"
import moment from "moment";

import BitMgmt from "../../utils/fetch/bitmgmt/bitmgmt";

import {
  fc_atoms
} from '../../uiSrc';

import { 
  setBitLocations,
  setBitLocationsDownloaded,
  setBitLocationsDPSExportData,
  setBitLocationsDPSExportLocation,
} from '../../reducers/locationsSlice';

import {
  setActivePage
} from '../../reducers/appSettingsSlice';

export const Locations = () => {

  /*TODO: As we consolidate the three code folders, use CenteredSpinner instead */
  /*NOTE: See Also the TODO on serverMonitor.js and Organizations.js */
  const {
    Spinner,
  } = fc_atoms;

  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);
  const BitLocations = useSelector((state) => state.Locations.locations, shallowEqual);
  const hasDownloaded = useSelector((state) => state.Locations.meta.hasDownloaded);
  
  const dispatch = useDispatch();

  const bitMgmtAPI = new BitMgmt();

  const dateFormattedForFilename = () => {
    const rightNow = new Date();
    const res = rightNow.toISOString().slice(0, 10).replace(/-/g, "");
    return res;
  };

  const getInit = async () => {
    const data = await bitMgmtAPI.getLocations();
    dispatch(setBitLocations(data));
    dispatch(setBitLocationsDownloaded(data));
  };

  const doDPSExports = async (location) => {
    const doExport = await bitMgmtAPI.getDPSExport(location);
    if (doExport.success) {
      // Do the csv download
      dispatch(setBitLocationsDPSExportData(doExport.data));
      dispatch(setBitLocationsDPSExportLocation(location));
    } else {
      alert("There has been an error with the export");
    }
    return true;
  };
  const dpsExportLocation = useSelector(
    (state) => state.Locations.DPSexport.location
  );
  const dpsExportData = useSelector(
    (state) => state.Locations.DPSexport.data
  );

  useEffect(() => {
    if (ActivePage !== "bit_locations") {
      dispatch(setActivePage("bit_locations"));
    }
    if (!hasDownloaded && !BitLocations.length) {
      getInit();
    }
  });

  const locationsTableColumns =[
    {name: "location_name", label: "Location Name", options: {sort: true, filter: true, sortThirdClickReset: true}},
    {name: "organization_name", label: "Organization Name", options: {sort: true, filter: true, sortThirdClickReset: true}},
    {name: "created", label: "Created", options: {sort: true, filter: true, sortThirdClickReset: true}},
    {name: "updated", label: "Updated", options: {sort: true, filter: true, sortThirdClickReset: true}},
    {name: "status", label: "Status", options: {sort: true, filter: true, sortThirdClickReset: true}},
    {name: "export", label: "DPS Export", options: {
      sort: false,
      filter: false,
      customBodyRenderLite: (dataIndex) => {
        const currentLocation = BitLocations[dataIndex].location_name
        return (<div>
            {dpsExportLocation === currentLocation ? (
                <CSVLink
                    headers={[
                      {label: "Company", key: "@company"},
                      {label: "debt_id", key: "debt_id"},
                      {label: "ssn", key: "ssn"},
                      {label: "lastname", key: "lastname"},
                      {label: "firstname", key: "firstname"},
                      {label: "address", key: "address"},
                      {label: "city", key: "city"},
                      {label: "state", key: "state"},
                      {label: "zip", key: "zip"},
                    ]}
                    data={dpsExportData}
                    target="_blank"
                    filename={`${
                        currentLocation
                    }.${dateFormattedForFilename()}_dps.csv`}
                    newLineSeparator={`\r\n`}
                >
                  Download {dpsExportData.length} records
                </CSVLink>
            ) : (
                <Button
                  variant={"outlined"}
                  sx={{backgroundColor: "#212121", color: "#fff", fontSize: "12px"}}
                  onClick={() => doDPSExports(currentLocation)}>
                    Export
                </Button>
            )}
        </div>)
        }
      }
    },
  ]

  return (
      <Box>
        <Card
            sx={{ minHeight: "calc(100vh - 150px)", padding: "8px", color: "#fff" }}
        >
          <CardHeader title={"All Locations"} />
          {hasDownloaded ? (
              BitLocations.length ? (
                <MUIDataTable
                  data={BitLocations}
                  columns={locationsTableColumns}
                  options={{
                    filterType: "textField",
                    selectableRows: "none",
                    responsive: "standard",
                    print: false,
                    download: false
                  }}
                />
            ) : (
              "No data found"
            )
          ) : (
            <div
              style={{
                width: "100%",
                height: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          )}
        </Card>
    </Box>
  );
};

export default Locations;