import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Import FC UI elements
import {
  fc_organisms,
  fc_molecules,
} from '../../uiSrc';


// Fetch API
import CampaignGetters from "../../utils/fetch/campaigns/campaignGetters";

// Actions
import {
  setActivePage
} from '../../reducers/appSettingsSlice';

import { 
  setCampaigns,
} from '../../reducers/campaignsSlice';

export const CampaignsPage = (props) => {
  const {
    Page,
    Grid,
  } = fc_organisms;

  const {
    Card,
  } = fc_molecules;

  const [isDownloaded, setIsDownloaded] = useState(false);
  const GridLayout = useSelector((state) => state.AppSettings.gridLayout.contacts);
  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);
  const campaignsResults = useSelector((state) => state.Campaigns.campaigns);
  
  const dispatch = useDispatch();
  const campaignGetters = new CampaignGetters();

  const getInit = async () => {
    const data = await campaignGetters.getCampaign();
    dispatch(setCampaigns(data));
  };

  useEffect(() => {
    if (ActivePage !== "campaigns") {
      dispatch(setActivePage("campaigns"));
    }
    if (!isDownloaded) {
      setIsDownloaded(true)
      getInit();
    }
  },[isDownloaded]);

  return (
    <Page>
      <Grid gridSize={GridLayout}>
        <Card
          title="Campaigns"
          style={{ height: "calc(100vh - 150px)" }}
          bodyStyle={{ height: "calc(100% - 60px)" }}
        >
          {JSON.stringify(campaignsResults)}
        </Card>
      </Grid>
    </Page>
  );
};

export default CampaignsPage;