import React, { useState } from "react";

// got them UI modules
import {
  fc_atoms
} from '../../uiSrc';
import Table from '../../organisms/activeDupsTable/activeDupsTable';
import styles from '../../pages/portfolioFile/portfolioFile.module.css';

const PortfolioLuhnTable = (props) => {
  const {
    data,
    toggleLuhn,
    submitDirectiveUpdate,
  } = props;

  const {
    Button,
    Spinner,
  } = fc_atoms;

  const [tableIndex,setTableIndex] = useState(0);
  const [tableSize, setTableSize] = useState(30);

  return (
    <Table
      data={data}
      pageIndexProp={tableIndex}
      pageSizeProp={tableSize}
      setPageSizeProp={setTableSize}
      setPageIndexProp={setTableIndex}
      sortObjProp={{
        Header: 'client',
        isSortedDesc: false,
      }}
      setSortObjProp={() => null}
      columns={[
        {
          Header: 'client',
          accessor: 'client',
          customHeader: 'Creditors',
        },
        {
          Header: 'accounts',
          accessor: 'accounts',
          customHeader: 'Total',
          headerStyle: {display: 'flex', justifyContent: 'flex-end', width: "100%", height: "100%"},
          Cell: ({row}) => {
            const {original} = row;
            return (
              <span className={styles.number}>{original.accounts}</span>
            );
          }
        },
        {
          Header: 'pass',
          accessor: 'pass',
          customHeader: 'Pass',
          headerStyle: {display: 'flex', justifyContent: 'flex-end', width: "100%", height: "100%"},
          Cell: ({row}) => {
            const {original} = row;
            const passCount = original.pass ? original.pass : 0;
            return (
              <span className={styles.number}>{passCount}</span>
            );
          }
        },
        {
          Header: 'fail',
          accessor: 'fail',
          customHeader: 'Fail',
          headerStyle: {display: 'flex', justifyContent: 'flex-end', width: "100%", height: "100%"},
          Cell: ({row}) => {
            const {original} = row;
            const failCount = original.fail ? original.fail : 0;
            return (
              <span className={styles.number}>{failCount}</span>
            );
          }
        },
        {
          Header: 'pass_percentage',
          accessor: 'pass_percentage',
          customHeader: 'Pass %',
          Cell: ({row}) => {
            const {original} = row;
            const percent = original.pass_percentage ? `${original.pass_percentage.toFixed(2)}%` : `0.00%`;
            return (
              <span>{percent}</span>
            );
          }
        },
        {
          Header: 'luhn_directive',
          accessor: 'luhn_directive',
          customHeader: 'Ignore',
          Cell: ({row}) => {
            const {original} = row;
            return (
              // <span>{original.luhn_directive}</span>
              <span>
                <Button
                  color={original.luhn_directive ? 'red' : null}
                  onClick={() => toggleLuhn(original.client,true,true)}
                  // disabled={original.luhn_directive ? true : false}
                >True</Button>
                <Button
                  style={{marginLeft: 5}}
                  color={!original.luhn_directive ? 'red' : null}
                  onClick={() => toggleLuhn(original.client,false,true)}
                  // disabled={original.luhn_directive ? false : true}
                >False</Button>
                {
                  original.luhn_updated === 'loading'
                  ? <Button
                      style={{marginLeft: 5}}
                    >
                      <Spinner />
                    </Button> 
                  : original.luhn_updated === true
                  ? <Button
                      style={{marginLeft: 5}}
                      onClick={() => submitDirectiveUpdate(original.client,original.luhn_directive,false)}
                    >Save</Button>
                  : null
                }
              </span>
            );
          }
        },
      ]}
    />
  );
};

export default PortfolioLuhnTable;