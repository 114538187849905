import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Box, TextField, CardHeader, Card, Button, Snackbar, Alert } from "@mui/material"
import MUIDataTable from "mui-datatables"


// API interface
import BitMgmt from "../../utils/fetch/bitmgmt/bitmgmt";

// Import FC UI libraries
import {
  fc_atoms
} from '../../uiSrc';

import { 
  updateLocationPortfolioWorklistAssignment,
  toggleLocationPortfolioIsSearching,
  toggleLocationPortfolioLocationSearchString,
  toggleLocationPortfolioPortfolioSearchString,
  setLocationPortfolios,
  setBitLocationPortfoliosDownloaded,
  setBitLocationPortfoliosFilterString,
} from '../../reducers/locationPortfoliosSlice';

import {
  setActivePage
} from '../../reducers/appSettingsSlice';
import StatsModal from "./statsModal";
import RemoteStatsModal from "./remoteStatsModal";

export const LocationPortfolios = () => {

  const {
    Spinner,
  } = fc_atoms;
  
  const [loading, setLoading] = useState([]);
  const [statsModalVisible, setStatsModalVisible] = useState(false);
  const [remoteStatsModalVisible, setRemoteStatsModalVisible] = useState(false);
  const [remoteStatsFetching, setRemoteStatsFetching] = useState("");
  const [portfolioStats, setPortfolioStats] = useState({
    missing_address: 0,
    missing_phone: 0,
    missing_ssn: 0,
    total: 0
  });
  const [remotePortfolioStats, setRemotePortfolioStats] = useState({
    stats2: [],
    stats7: [],
  });
  const [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false)
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false)
  const [successSnackBarText, setSuccessSnackBarText] = useState("")
  const [errorSnackBarText, setErrorSnackBarText] = useState("")

  const handleSnackbarClose = () => {
    setSuccessSnackBarOpen(false)
    setErrorSnackBarOpen(false)
    setErrorSnackBarText(" ")
    setSuccessSnackBarText(" ")
  };

  const dispatch = useDispatch();
  const bitMgmtAPI = new BitMgmt();

  const viewStatsModal = React.useMemo(() => (
      <StatsModal modalOpen={statsModalVisible} onClose={() => setStatsModalVisible(false)} data={portfolioStats}/>
    ),
    [statsModalVisible, portfolioStats]
  );

  const viewRemoteStatsModal = React.useMemo(() => (
      <RemoteStatsModal
          modalOpen={remoteStatsModalVisible}
          onClose={() => setRemoteStatsModalVisible(false)}
          data={remotePortfolioStats}
      />
    ),
    [remoteStatsModalVisible, remotePortfolioStats]
  );

  const dataTableColumns = [
    {name: "name", label: "Name", options: {
        sort: true,
        filter: true,
        sortThirdClickReset: true}},
    {name: "location_name", label: "Location Name", options: {
      sort: true,
      filter: true,
      sortThirdClickReset: true}},
    {name: "created", label: "Created", options: {sort: true, filter: false, sortThirdClickReset: true}},
    {name: "updated", label: "Updated", options: {sort: true, filter: false, sortThirdClickReset: true}},
    {name: "location_portfolio_id", label: "Location Portfolio ID", options: {sort: true, filter: true, sortThirdClickReset: true}},
    {name: "num_accounts", label: "Num Accounts", options: {sort: true, filter: false}},
    {name: "not_loaded", label: "Not Loaded", options: {sort: true, filter: false, sortThirdClickReset: true}},
    {name: " ", label: "Upload", options: {
      sort: false,
      filter: false,
      customBodyRenderLite: (dataIndex) => {
       if (loading.includes(dataIndex)) {
         return (
           <Button variant="contained">
             <Spinner />
           </Button>)
       } else {
         return (
            <Button
                sx={{color: "#fff", backgroundColor: "#2e2e2e", fontSize: "12px"}}
                variant="contained"
                onClick={() => {
                  handleUpload(LocationPortfolios[dataIndex], dataIndex)
                }}
            >Import at Location</Button>
        )}
      }}},
    {name: " ", label: "Reassign", options: {
      sort: false,
      filter: false,
      customBodyRenderLite: (dataIndex) =>{
        const org = LocationPortfolios[dataIndex]

        if (activeReassignKey.location === org.location_name &&
            activeReassignKey.portfolio === org.name) {
          return (
              <div style={{display: "flex", alignItems: "center"}}>
                <label style={{paddingLeft: "1em", paddingRight: "1em"}}>
                  From
                </label>
                <select
                    onChange={(e) => updateWorklistReassignFrom(e.target.value)}
                >
                  <option value=""></option>
                  <option value="BSN">BSN</option>
                  <option value="RCR">RCR</option>
                  <option value="BTS">BTS</option>
                  <option value="NEW">NEW</option>
                </select>
                <label style={{paddingLeft: "1em", paddingRight: "1em"}}>
                  #&nbsp;of&nbsp;Accounts
                </label>
                <input type="number" min={0} style={{
                  width: '75px',
                  backgroundColor: "var(--darkest)",
                  color: "#fff",
                  paddingTop: "5px",
                  paddingBottom: "5px"
                }} onChange={(e) => updateWorklistReassignCount(e.target.value)}/>
                <label style={{paddingLeft: "1em", paddingRight: "1em"}}>
                  To
                </label>
                <select onChange={(e) => updateWorklistReassignTo(e.target.value)}>
                  <option value=""></option>
                  <option value="BSN">BSN</option>
                  <option value="RCR">RCR</option>
                  <option value="BTS">BTS</option>
                  <option value="NEW">NEW</option>
                </select>
                <Button variant="contained" sx={{color: "#fff", backgroundColor: "#2e2e2e", fontSize: "11px", marginRight: "2px"}} onClick={() => executePortfolioLocationAssignmentChange()}>Execute</Button>
                <Button variant="contained" sx={{color: "#fff", backgroundColor: "#2e2e2e", fontSize: "11px"}} onClick={() => dispatch(updateLocationPortfolioWorklistAssignment({}))}>Clear</Button>
              </div>
          )
        } else {
          /* Begin */
          return (
              <Button
                  sx={{color: "#fff", backgroundColor: "#2e2e2e", fontSize: "12px"}}
                  variant="contained"
                  onClick={() => dispatch(updateLocationPortfolioWorklistAssignment({
                    location: org.location_name,
                    portfolio: org.name,
                  }))}
              >Change Worklist</Button>
          )
        }
      }
      }
    },
    {
      name: " ", label: "Mark Portfolio Loaded", options: {
        sort: false,
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const orgId = LocationPortfolios[dataIndex].id
          return (
              <Button
                  sx={{color: "#fff", backgroundColor: "#2e2e2e", fontSize: "12px"}}
                  variant="contained"
                  onClick={async () => {
                    (await bitMgmtAPI.markBitLocationPortfolioLoaded(orgId).then())
                    handleFilterInput();
                    setSuccessSnackBarText("Portfolio successfully loaded")
                    setSuccessSnackBarOpen(true)
                  }}
              >Mark Loaded</Button>
          )
        }
      }
    },
    {
      name: " ", label: "Stats", options: {
        sort: false,
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const org = LocationPortfolios[dataIndex]
          return (
              <Button
                  sx={{color: "#fff", backgroundColor: "#2e2e2e", fontSize: "12px"}}
                  variant="contained"
                  onClick={async () => {
                    setPortfolioStats(await bitMgmtAPI.getLocationPortfolioStats(org.location_portfolio_id));
                    setStatsModalVisible(true);
                  }}
              >View Stats</Button>
          )
        }
      }
    },
    {
      name: " ", label: "Remote Stats", options: {
        sort: false,
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const org = LocationPortfolios[dataIndex]
          return (
              <Button
                  sx={{color: "#fff", backgroundColor: "#2e2e2e", fontSize: "12px"}}
                  variant="contained"
                  onClick={ async () => {
                    setRemoteStatsFetching(org.name)
                    const response = await bitMgmtAPI.getRemotePortfolioStats(org.location_name, org.name)
                    if(response.success) {
                      setRemotePortfolioStats({
                        stats2: Object.keys(response.data.stats2[0]).map(columnTitle => ({title: columnTitle, values: response.data.stats2.map(row => (row[columnTitle]))})),
                        stats7: response.data.stats7
                      });
                      setRemoteStatsModalVisible(true);
                      setRemoteStatsFetching("")
                    } else {
                      setErrorSnackBarText('Unable to load remote portfolio stats for portfolio `' + org.name + '`\nError: ' + response.error)
                      setErrorSnackBarOpen(true)
                      setRemoteStatsFetching("")
                    }
                  }}
              >Remote Stats</Button>
          )
        }
      }
    },
  ]

  // Write
  const handleUpload = async (el, i) => {
    const nLoading = [...loading];
    nLoading.push(i);
    setLoading(nLoading);
    // const location = urlParams.get("loc");
    const uploadResult = await bitMgmtAPI.uploadBitLocationPortfolio(
      el.location_portfolio_id,
      el.location_name
    );
    const sLoading = [...loading];
    sLoading.splice(sLoading.indexOf(i), 1);
    setLoading(sLoading);
    if (uploadResult.error) {
      setErrorSnackBarText(`Error: ${uploadResult.error}`)
      setErrorSnackBarOpen(true)
    } else if (uploadResult.success && uploadResult.data) {
      setSuccessSnackBarText(`Successful records: ${uploadResult.data}`)
      setSuccessSnackBarOpen(true)
      handleFilterInput();
    } else {
      alert(`Unkown error: ${JSON.stringify(uploadResult)}`);
      // After a successful upload, the loaded number should have changed
      handleFilterInput();
    }
  };

  // Write
  const executePortfolioLocationAssignmentChange = async () => {
    const { location, portfolio, from, to, count } = activeReassignKey;
    if (from === undefined || to === undefined || count === undefined) {
      alert("please fill out the required fields")
    } else {
      const workListUpdateResults = await bitMgmtAPI.updateLocationPortfolioWorklist(
          location,
          portfolio,
          from,
          to,
          count
      );
      if (workListUpdateResults.data && workListUpdateResults.data.length) {
        const alertMapped = workListUpdateResults.data.reduce((p, c) => {
          return p + c.assign_id + ": " + c.total_assigned + " \n";
        }, "");
        setSuccessSnackBarText(alertMapped)
        setSuccessSnackBarOpen(true)
        dispatch(updateLocationPortfolioWorklistAssignment({}));
      } else {
        setErrorSnackBarText("There was an error assigning portfolios. If issue persists, contact support")
        setErrorSnackBarOpen(true)
      }
    }
  };

  const updateWorklistReassignFrom = (val) => {
    const tmp = { ...activeReassignKey };
    tmp.from = val;
    dispatch(updateLocationPortfolioWorklistAssignment(tmp));
  };
  const updateWorklistReassignTo = (val) => {
    const tmp = { ...activeReassignKey };
    tmp.to = val;
    dispatch(updateLocationPortfolioWorklistAssignment(tmp));
  };
  const updateWorklistReassignCount = (val) => {
    const tmp = { ...activeReassignKey };
    tmp.count = val;
    dispatch(updateLocationPortfolioWorklistAssignment(tmp));
  };

  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);
  
  const activeReassignKey = useSelector(
    (state) => state.LocationPortfolios.activeReassignKey
  );
  const LocationPortfolios = useSelector(
    (state) => state.LocationPortfolios.portfolios,
    shallowEqual
  ).map((org, i) => {
    const tmp = {...org};
    /* This Action button is a dead end, doesn't have an endpoint, and it's not immediately obvious what it was supposed to do.
    *  For these reasons, it's being commented out until further notice. TODO: Determine if this is a thing that should exist.
    * */
    /*tmp.action = (
      <a
        href={`/locationPortfolioExport?org=${org.name}&loc=${org.location_name}`}
        target="_blank"
        rel="noreferrer"
      >
        <Button onClick={() => null}>Location&nbsp;files&nbsp;list</Button>
      </a>
    );*/
    tmp.upload = loading.includes(i) ? (
      <Button onClick={() => handleUpload(org, i)} disabled={""}>
        <Spinner size={12} />
      </Button>
    ) : (
      <Button onClick={() => handleUpload(org, i)} disabled={""}>
        Import&nbsp;at&nbsp;location
      </Button>
    );
    tmp.reassign = (
      <div>
        {activeReassignKey.location === org.location_name &&
        activeReassignKey.portfolio === org.name ? (
          //  BSN, RCR, BTS, NEW
          <div style={{ display: "flex", alignItems: "center" }}>
            <label style={{ paddingLeft: "1em", paddingRight: "1em" }}>
              From
            </label>
            <select
              onChange={(e) => updateWorklistReassignFrom(e.target.value)}
            >
              <option value=""></option>
              <option value="BSN">BSN</option>
              <option value="RCR">RCR</option>
              <option value="BTS">BTS</option>
              <option value="NEW">NEW</option>
            </select>
            <label style={{ paddingLeft: "1em", paddingRight: "1em" }}>
              #&nbsp;of&nbsp;Accounts
            </label> 
            <input type="number" min={0} style={{width: '75px', backgroundColor: "var(--darkest)", color: "#fff", paddingTop: "5px", paddingBottom: "5px" }} onChange={(e) => updateWorklistReassignCount(e.target.value)} />
            <label style={{ paddingLeft: "1em", paddingRight: "1em" }}>
              To
            </label>
            <select onChange={(e) => updateWorklistReassignTo(e.target.value)}>
              <option value=""></option>
              <option value="BSN">BSN</option>
              <option value="RCR">RCR</option>
              <option value="BTS">BTS</option>
              <option value="NEW">NEW</option>
            </select>
            <Button
              onClick={() => executePortfolioLocationAssignmentChange()}
              style={{ marginLeft: "1em" }}
            >
              Execute
            </Button>
            <Button 
              onClick={() => {
                dispatch(updateLocationPortfolioWorklistAssignment({}));
              }
            }>
              Clear
            </Button>
          </div>
        ) : (
          <Button
            onClick={() =>
              dispatch(updateLocationPortfolioWorklistAssignment({
                location: org.location_name,
                portfolio: org.name,
              }))
            }
          >
            Change&nbsp;Worklist
          </Button>
        )}
      </div>
    );
    tmp.mark_portfolio_loaded = (
      <Button
        onClick={async () => {
          console.log(`Begin marking this portfolio ${org.location_portfolio_id} loaded `);
          (await bitMgmtAPI.markBitLocationPortfolioLoaded(org.location_portfolio_id))
          handleFilterInput();
          console.log(`End marking ${org.location_portfolio_id} loaded`);
        }}
      >
        Mark&nbsp;Loaded
      </Button>
    );
    tmp.stats = (
        <Button
            onClick={async () => {
              console.log(`get location stats for location_portfolio_id ${org.location_portfolio_id}`);
              setPortfolioStats(await bitMgmtAPI.getLocationPortfolioStats(org.location_portfolio_id));
              setStatsModalVisible(true);
            }}
        >
          View Stats
        </Button>
    );
    tmp.remote_stats = (
        (remoteStatsFetching === org.name) ?
          <Spinner/>
        :
        <Button
            onClick={async () => {
              setRemoteStatsFetching(org.name)
              const response = await bitMgmtAPI.getRemotePortfolioStats(org.location_name, org.name)
              if(response.success) {
                setRemotePortfolioStats({
                  stats2: Object.keys(response.data.stats2[0]).map(columnTitle => ({title: columnTitle, values: response.data.stats2.map(row => (row[columnTitle]))})),
                  stats7: response.data.stats7
                });
                setRemoteStatsModalVisible(true);
                setRemoteStatsFetching("")
              } else {
                setErrorSnackBarText('Unable to load remote portfolio stats for portfolio `' + org.name + '`\nError: ' + response.error)
                setErrorSnackBarOpen(true)
                setRemoteStatsFetching("")
              }
            }}
        >
          Remote Stats
        </Button>
    );
    return tmp;
  });
  const filterString = useSelector(
    (state) => state.LocationPortfolios.meta.filterString
  );
  const locationSearchString = useSelector(
    (state) => state.LocationPortfolios.meta.locationSearchString
  );
  const portoflioSearchString = useSelector(
    (state) => state.LocationPortfolios.meta.portfolioSearchString
  );
  const pageSize = useSelector(
    (state) => state.LocationPortfolios.meta.pageSize
  );
  const pageIndex = useSelector(
    (state) => state.LocationPortfolios.meta.pageIndex
  );
  const sortObj = useSelector((state) => state.LocationPortfolios.meta.sortObj);


  const isSearching = useSelector(
    (state) => state.LocationPortfolios.meta.isSearching
  );

  // Read
  const handleFilterInput = async () => {
    dispatch(toggleLocationPortfolioIsSearching(true));
    const data = await bitMgmtAPI.getLocationPortfolios({
      locationSearchString,
      portoflioSearchString,
    });
    dispatch(setLocationPortfolios(data));
    dispatch(setBitLocationPortfoliosDownloaded(true));
    dispatch(toggleLocationPortfolioIsSearching(false));
  };

  useEffect(() => {
    if (ActivePage !== "location_portfolios") {
      dispatch(setActivePage("location_portfolios"));
    }
  });

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 13) {
      handleFilterInput()
    }
  };

  return (
    <Box>
        <Card sx={{color: "#fff", padding: "8px"}}>
          <CardHeader title="Location Portfolios"/>
          <Box sx={{marginBottom: "16px", display: "flex", flexDirection: {xs: "column", md: "row"}}}>
            <TextField
                name="locationSearch"
                label="Location Search"
                onChange={(event) => dispatch(toggleLocationPortfolioLocationSearchString(event.target.value))}
                onKeyDown={handleKeyDown}
                sx={{marginRight: "8px"}}
            />
            <TextField
              name="portfolioSearch"
              label="Portfolio Search"
              onChange={(event) => dispatch(toggleLocationPortfolioPortfolioSearchString(event.target.value))}
              onKeyDown={handleKeyDown}
              sx={{marginRight: "8px"}}
            />
            <TextField
              name="filter"
              label="Filter"
              onChange={(event) => dispatch(setBitLocationPortfoliosFilterString(event.target.value))}
              onKeyDown={handleKeyDown}
            />
          </Box>
          {isSearching ? (
            <div
              style={{
                width: "100%",
                height: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          ) : LocationPortfolios.length ? (
              <MUIDataTable
                  data={LocationPortfolios}
                  columns={dataTableColumns}
                  options={{
                    filterType: "textField",
                    selectableRows: "none",
                    responsive: "standard",
                    print: false,
                    download: false
                  }}
              />
          ) : (
            <div
              style={{
                width: "100%",
                height: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No matching portfolios. Enter a new search term
            </div>
          )}
        </Card>
      {viewStatsModal}
      {viewRemoteStatsModal}
        <Snackbar
            open={errorSnackBarOpen}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
        >
            <Alert
                onClose={handleSnackbarClose}
                severity={"error"}
                variant={"filled"}
                sx={{width: "100%"}}
            >
                {errorSnackBarText}
            </Alert>
        </Snackbar>
        <Snackbar
            open={successSnackBarOpen}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
        >
            <Alert
                onClose={handleSnackbarClose}
                severity={"success"}
                variant={"filled"}
                sx={{width: "100%"}}
            >
                {successSnackBarText}
            </Alert>
        </Snackbar>
    </Box>
  );
};

export default LocationPortfolios;