/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ThemeProvider,
  Container,
} from '@mui/material'
import theme from "../../theme/theme"

import {
  setActivePage
} from '../../reducers/appSettingsSlice'
/* API METHODS */
import CRMGetters from "../../utils/fetch/crm/crmGetters";
import CRMPutters from "../../utils/fetch/crm/crmPutters";

import validator from "validator";
import ContactOverview from "../../organisms/contact/contactOverview";
import ContactDataCard from "../../organisms/contact/contactDataCard";
import AddEditEmailModal from "../../organisms/contact/addEmailModal";
import AddEditAddressModal from "../../organisms/contact/addAddressModal";
import AddEditPhoneModal from "../../organisms/contact/addPhoneModal";
import EditUserDetailsModal from "../../organisms/contact/editUserDetailsModal";
import CenteredSpinner from "../../organisms/CenteredSpinner";

export const Contact = (props) => {
  const crmGetters = new CRMGetters();
  const crmPutters = new CRMPutters();

  const { match } = props;
  const {
    params: { contactUID },
  } = match;

  const dispatch = useDispatch();

  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);

  const [currentContact, setCurrentContact] = useState({
    general: {},
    emails: [],
    phones: [],
    addresses: [],
  });

  /* generalFetching indicates whether we're in a loading state and awaiting updated data*/
  const [generalFetching, setGeneralFetching] = useState(true);

  /*These pieces of state govern whether we're viewing archived data or active data (datacard tabs) */
  const [archivedEmail, setArchivedEmail] = useState(0);
  const [archivedPhone, setArchivedPhone] = useState(0);
  const [archivedAddress, setArchivedAddress] = useState(0);

  /*These pieces of state govern if we're editing a record rather than adding one*/
  const [activeEmailEditId, setActiveEmailEditId] = useState(-1);
  const [activePhoneEditId, setActivePhoneEditId] = useState(-1);
  const [activeAddressEditId, setActiveAddressEditId] = useState(-1);

  /*These pieces of state govern which modals are visible*/
  const [userDetailsModalVisible, setUserDetailsModalVisible] = useState(false);
  const [addEmailModalVisible, setAddEmailModalVisible] = useState(false);
  const [addPhoneModalVisible, setAddPhoneModalVisible] = useState(false);
  const [addAddressModalVisible, setAddAddressModalVisible] = useState(false);


  useEffect(() => {
    if (ActivePage !== "view_contact") {
      dispatch(setActivePage("view_contact"));
    }
    pullContactData(contactUID);
  }, [contactUID]);

  useEffect(() => {
    if (activePhoneEditId !== -1){
      setAddPhoneModalVisible(true);
    }
    if (activeEmailEditId !== -1){
      setAddEmailModalVisible(true)
    }
    if (activeAddressEditId !== -1){
      setAddAddressModalVisible(true)
    }
  }, [activeEmailEditId, activePhoneEditId, activeAddressEditId])

  const pullContactData = async () => {
    const cid = contactUID;
    setGeneralFetching(true);
    const data = await crmGetters.getContact(cid);
    const newGeneral =
      data.general && data.general.length ? { ...data.general[0] } : {};
    const newEmails = data.emails && data.emails.length ? [...data.emails] : [];
    const newPhones = data.phones && data.phones.length ? [...data.phones] : [];
    const newAddresses =
      data.addresses && data.addresses.length ? [...data.addresses] : [];
    if (!newGeneral.first) {
      props.history.push("/contacts");
    }
    setCurrentContact({
      general: newGeneral,
      emails: newEmails,
      phones: newPhones,
      addresses: newAddresses,
    });
    
    setGeneralFetching(false);
  };

  const onSubmitUserDetails = async (data) => {
    const crmPutters = new CRMPutters();
    const submissionData = {...data,  contactID: currentContact.general.id}
    setUserDetailsModalVisible(false)
    setGeneralFetching(true);
    const ok = await crmPutters.updateContact(submissionData);
    if (ok) {
      await pullContactData(currentContact.general.id);
    } else {
      alert("An error occurred. Could not update contact.");
      setGeneralFetching(false);
    }
  };

  const onSubmitEmailAdd = async (data) => {
    const crmPutters = new CRMPutters();
    let adjustedData = {...data, contactID: currentContact.general.id}

    setAddEmailModalVisible(false);
    setGeneralFetching(true);
    const ok = await crmPutters.addContactEmail(adjustedData);
    if (ok) {
      await pullContactData(currentContact.general.id);
    } else {
      alert("An error occurred. Could not update contact.");
      setGeneralFetching(false);
    }
  };

  const onSubmitEmailEdit = async (data) => {
    setGeneralFetching(true);
    const submissionData = {...data, contactID: currentContact.general.id}
    const ok = await crmPutters.editContactEmail(submissionData);
    if (ok) {
      setActiveEmailEditId(-1);
      setAddEmailModalVisible(false)
      await pullContactData(currentContact.general.id);
    } else {
      alert("An error occurred. Could not update contact.");
      setGeneralFetching(false);
    }
  };

  const onDeleteEmail = async (id) => {
    if (window.confirm("Are you sure you want to delete this email?")) {
      setGeneralFetching(true);
      const data = { email_id: id, contactID: currentContact.general.id };
      const ok = await crmPutters.deleteContactEmail(data);
      if (ok) {
        await pullContactData(currentContact.general.id);
      } else {
        alert("An error occurred. Could not update contact.");
        setGeneralFetching(false);
      }
    }
  };

  const onSubmitPhoneAdd = async (data) => {
    const crmPutters = new CRMPutters();

    /*
      Because the validator has a check for invalid country codes, I am leaving this check here.
      We can reassess whether or not this particular validator is necessary
    */

    if (!validator.isMobilePhone(data.number)) {
      alert("Please enter a valid phone number.");
      return;
    }
    setAddPhoneModalVisible(false);
    setGeneralFetching(true);
    const submissionData = {...data, contactID: currentContact.general.id}
    const ok = await crmPutters.addContactPhone(submissionData);
    if (ok) {
      await pullContactData(currentContact.general.id);
    } else {
      alert("An error occurred. Could not update contact.");
      setGeneralFetching(false);
    }
  };

  const onSubmitPhoneEdit = async (data) => {
    /*
      Because the validator has a check for invalid country codes, I am leaving this check here.
      We can reassess whether or not this particular validator is necessary
    */
    if (!validator.isMobilePhone(data.number)) {
      alert("Please enter a valid phone number.");
      return;
    }
    setGeneralFetching(true);
    const submissionData = {...data, contactID: currentContact.general.id}
    const ok = await crmPutters.editContactPhone(submissionData);
    if (ok) {
      setActivePhoneEditId(-1);
      setAddPhoneModalVisible(false)
      await pullContactData(currentContact.general.id);
    } else {
      alert("An error occurred. Could not update contact.");
      setGeneralFetching(false);
    }
  };

  const onDeletePhone = async (id) => {
    if (window.confirm("Are you sure you want to delete this phone?")) {
      setGeneralFetching(true);
      const data = { phone_id: id };
      data.contactID = currentContact.general.id;
      const ok = await crmPutters.deleteContactPhone(data);
      if (ok) {
        setActivePhoneEditId(-1);
        await pullContactData(currentContact.general.id);
      } else {
        alert("An error occured. Could not update contact.");
        setGeneralFetching(false);
      }
    }
  };

  const onSubmitAddressAdd = async (data) => {
    setAddAddressModalVisible(false);
    setGeneralFetching(true);
    const submissionData = {...data, contactID: currentContact.general.id}
    const ok = await crmPutters.addContactAddress(submissionData);
    if (ok) {
      await pullContactData(currentContact.general.id);
    } else {
      alert("An error occurred. Could not update contact.");
      setGeneralFetching(false);
    }
  };

  const onSubmitAddressEdit = async (data) => {
    setGeneralFetching(true);
    const submissionData = {...data, contactID: currentContact.general.id}
    const ok = await crmPutters.editContactAddress(submissionData);
    if (ok) {
      setActiveAddressEditId(-1);
      setAddAddressModalVisible(false)
      await pullContactData(currentContact.general.id);
    } else {
      alert("An error occurred. Could not update contact.");
      setGeneralFetching(false);
    }
  };

  const onDeleteAddress = async (id) => {
    if (window.confirm("Are you sure you want to delete this address?")) {
      setGeneralFetching(true);
      const data = { id };
      data.contactID = currentContact.general.id;
      const ok = await crmPutters.deleteContactAddress(data);
      if (ok) {
        setActiveAddressEditId(-1);
        pullContactData(currentContact.general.id);
      } else {
        alert("An error occurred. Could not update contact.");
        setGeneralFetching(false);
      }
    }
  };

  return (
      <ThemeProvider theme={theme}>
      {generalFetching ? (
          <CenteredSpinner />
      ) : (
        <>
          <Container sx={{minWidth: '100%', minHeight: "100%",}}>
            <ContactOverview
                setModalVisible={setUserDetailsModalVisible}
                generalContactData={currentContact.general}
            />
            <ContactDataCard
                dataType="Phone"
                setAddModalVisible={() => {setAddPhoneModalVisible(true)}}
                tableData={[...currentContact.phones]}
                editFunction={(id) => {setActivePhoneEditId(id)}}
                deleteFunction={(id) => onDeletePhone(id)}
                archivedData={archivedPhone}
                setArchivedData={setArchivedPhone}
                theme={theme}
            />
            <ContactDataCard
                dataType="Email"
                setAddModalVisible={() => {setAddEmailModalVisible(true)}}
                tableData={[...currentContact.emails]}
                editFunction={(id) => setActiveEmailEditId(id)}
                deleteFunction={(id) => onDeleteEmail(id)}
                archivedData={archivedEmail}
                setArchivedData={setArchivedEmail}
                theme={theme}
            />
            <ContactDataCard
                dataType="Address"
                setAddModalVisible={() => {setAddAddressModalVisible(true)}}
                tableData={[...currentContact.addresses]}
                editFunction={(id) => setActiveAddressEditId(id)}
                deleteFunction={(id) => onDeleteAddress(id)}
                archivedData={archivedAddress}
                setArchivedData={setArchivedAddress}
                theme={theme}
            />
          </Container>
        </>
      )}
      <EditUserDetailsModal
        modalOpen={userDetailsModalVisible}
        setModalOpen={setUserDetailsModalVisible}
        submitFunction={onSubmitUserDetails}
        dataToEdit={currentContact.general}
      />
      <AddEditPhoneModal
        modalOpen={addPhoneModalVisible}
        setModalOpen={setAddPhoneModalVisible}
        addFunction={onSubmitPhoneAdd}
        editFunction={onSubmitPhoneEdit}
        editID={activePhoneEditId}
        editData={currentContact.phones}
        setActivePhoneEditId={setActivePhoneEditId}
      />
      <AddEditAddressModal
        modalOpen={addAddressModalVisible}
        setModalOpen={setAddAddressModalVisible}
        addFunction={onSubmitAddressAdd}
        editFunction={onSubmitAddressEdit}
        editID={activeAddressEditId}
        editData={currentContact.addresses}
        setActiveAddressEditId={setActiveAddressEditId}
      />
      <AddEditEmailModal
        modalOpen={addEmailModalVisible}
        setModalOpen={setAddEmailModalVisible}
        addFunction={onSubmitEmailAdd}
        editFunction={onSubmitEmailEdit}
        editID={activeEmailEditId}
        editData={currentContact.emails}
        setActiveEmailEditId={setActiveEmailEditId}
      />
      </ThemeProvider>
  );
};


export default Contact;