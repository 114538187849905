// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.keyword {
  color: var(--green);
}
.operator {
  color: var(--red);
}
.string {
  color: var(--light);
}
.number {
  color: var(--lighter);
}
.punctuation {
  color: var(--white);
}
`, "",{"version":3,"sources":["webpack://./src/pages/queryTool/editorStyles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".keyword {\n  color: var(--green);\n}\n.operator {\n  color: var(--red);\n}\n.string {\n  color: var(--light);\n}\n.number {\n  color: var(--lighter);\n}\n.punctuation {\n  color: var(--white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
