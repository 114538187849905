// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../usr/local/share/.config/yarn/global/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button .spinner_parent__KAioT {
  transform: translateY(2px);
}
`, "",{"version":3,"sources":["webpack://./src/atoms/spinner/spinner.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;AAC5B","sourcesContent":["button .parent {\n  transform: translateY(2px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"parent": `spinner_parent__KAioT`
};
export default ___CSS_LOADER_EXPORT___;
